import React, { useState, useContext } from "react";
import "./BaseCamp.css";
import backgroundImage from "@assets/baseCampAssets/baseCampBg.png";
import backgroundImageWebp from "@assets/baseCampAssets/baseCampBg.webp";
import LazyImage from "@components/LazyImage";
import DefinitionPanel from "./definitionPanel/DefinitionPanel";
import { LangContext } from "@context/LangContext";
import { panelTexts } from "@data/panelData";

const BaseCamp = () => {
  const [openPanelIndex, setOpenPanelIndex] = useState(null);
  const { lang } = useContext(LangContext);

  const handleTogglePanel = (index) => {
    setOpenPanelIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="baseCamp">
      <LazyImage
        className="baseCampBg"
        srcPng={backgroundImage}
        srcWebp={backgroundImageWebp}
        alt=""
      />

      {panelTexts[lang].map((panelData, index) => (
        <DefinitionPanel
          key={index}
          title={panelData.title}
          definitions={panelData.definitions}
          top={panelData.top}
          left={panelData.left}
          isOpen={openPanelIndex === index}
          onToggle={() => handleTogglePanel(index)}
          panelIndex={index}
        />
      ))}
    </div>
  );
};

export default BaseCamp;
