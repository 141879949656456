import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Navbar.css";
import ScrollToBottomButton from "./scrollToBottom/ScrollToBottomButton";
import LanguageSwitcher from "./languageSwitcher/LanguageSwitcher";
import MusicPlayer from "./musicComponent/MusicPlayer";
import { LangContext } from "@context/LangContext";
import { texts } from "@data/textsData";
import LogoAnimation from "./logoComponent/LogoAnimation";
import HamburgerMenu from "./hamburgerMenu/HamburgerMenu";
import FullscreenToggleButton from "./fullScreenButton/FullscreenToggleButton";
import { BubbleContext } from "@context/BubbleContext";
import { logButtonClick } from "@utils/analytics";
import baseCampLogo from "@assets/baseCampAssets/baseCampLogo.png";

const Navbar = () => {
  const { lang } = useContext(LangContext);
  const { minimizeAllBubbles } = useContext(BubbleContext);
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isBaseCampHovered, setIsBaseCampHovered] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isBaseCampPage = location.pathname === "/agileKiosk";

  const scrollToPercentage = (percentage) => {
    const scrollHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollToPosition = (percentage / 100) * scrollHeight;
    window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
  };

  useEffect(() => {
    let timeoutId;
    if (isHovered) {
      setIsVisible(true);
    } else {
      timeoutId = setTimeout(() => setIsVisible(false), 3000);
    }
    return () => clearTimeout(timeoutId);
  }, [isHovered]);

  return (
    <div
      id="navbar"
      className={isBaseCampPage ? "basecamp" : ""}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div id="navbarInner">
        <div onClick={() => scrollToPercentage(0)}>
          <LogoAnimation />
        </div>
        <div id="navItems" className={isVisible ? "visible" : ""}>
          {!isBaseCampPage && (
            <>
              <span
                className="nav-home"
                onClick={() => {
                  logButtonClick("Navbar", "Click", "Home");
                  scrollToPercentage(8);
                }}
              >
                {texts[lang].navbar.home}
              </span>
              <div className="nav-item-dropdown">
                <span
                  className="nav-skills"
                  onClick={() => {
                    logButtonClick("Navbar", "Click", "Skills");
                    scrollToPercentage(25);
                  }}
                >
                  {texts[lang].navbar.skills.main}
                </span>
                <div className="dropdown-content">
                  <span
                    className="nav-dropAgil"
                    onClick={() => {
                      logButtonClick("Navbar", "Click", "Agile Skills");
                      scrollToPercentage(29);
                    }}
                  >
                    {texts[lang].navbar.skills.dropAgil}
                  </span>
                  <span
                    className="nav-dropTech"
                    onClick={() => {
                      logButtonClick("Navbar", "Click", "Tech Skills");
                      scrollToPercentage(48);
                    }}
                  >
                    {texts[lang].navbar.skills.dropTech}
                  </span>
                </div>
              </div>
              <span
                className="nav-role"
                onClick={() => {
                  logButtonClick("Navbar", "Click", "Role");
                  scrollToPercentage(61);
                }}
              >
                {texts[lang].navbar.role}
              </span>

              <div className="nav-item-dropdown">
                <span
                  className="nav-project"
                  onClick={() => {
                    logButtonClick("Navbar", "Click", "Projects");
                    scrollToPercentage(68);
                  }}
                >
                  {texts[lang].navbar.project.main}
                </span>
                <div className="dropdown-content">
                  <span
                    className="nav-proProj"
                    onClick={() => {
                      logButtonClick("Navbar", "Click", "Professional Project");
                      scrollToPercentage(68);
                    }}
                  >
                    {texts[lang].navbar.project.proProj}
                  </span>
                  <span
                    className="nav-persoProj"
                    onClick={() => {
                      logButtonClick("Navbar", "Click", "Personal Project");
                      scrollToPercentage(72);
                    }}
                  >
                    {texts[lang].navbar.project.persoProj}
                  </span>
                </div>
              </div>

              <span
                className="nav-journey"
                onClick={() => {
                  logButtonClick("Navbar", "Click", "Journey");
                  scrollToPercentage(86);
                }}
              >
                {texts[lang].navbar.journey}
              </span>

              <div className="nav-item-dropdown">
                <span
                  className="nav-contact"
                  onClick={() => {
                    logButtonClick("Navbar", "Click", "Contact");
                    scrollToPercentage(91);
                  }}
                >
                  {texts[lang].navbar.contact.main}
                </span>

                <div className="dropdown-content">
                  <span
                    className="nav-contact"
                    onClick={() => {
                      logButtonClick("Navbar", "Click", "Resume");
                      scrollToPercentage(94.2);
                    }}
                  >
                    {texts[lang].navbar.contact.resume}
                  </span>
                  <span
                    className="nav-contact"
                    onClick={() => {
                      logButtonClick("Navbar", "Click", "Contact Main");
                      scrollToPercentage(99);
                    }}
                  >
                    {texts[lang].navbar.contact.main}
                  </span>
                </div>
              </div>

              <div className="vertical-separator"></div>
              <span
                className="nav-baseCamp"
                onClick={() => {
                  logButtonClick("Navbar", "Click", "agileKiosk");
                  navigate("/agileKiosk");
                }}
                onMouseEnter={() => setIsBaseCampHovered(true)}
                onMouseLeave={() => setIsBaseCampHovered(false)}
              >
                <div
                  className="baseCamp-bg"
                  style={{
                    backgroundImage: isBaseCampHovered
                      ? `url(${baseCampLogo})`
                      : "none",
                    opacity: 0.6,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    transition: "opacity 0.5s ease",
                  }}
                />
                {texts[lang].navbar.baseCamp.split(" ")[0]}
                <br />
                {texts[lang].navbar.baseCamp.split(" ")[1]}
              </span>
            </>
          )}

          {isBaseCampPage && (
            <span
              className="nav-homePage"
              onClick={() => {
                logButtonClick("Navbar", "Click", "HomePage");
                navigate("/");
              }}
            >
              {texts[lang].navbar.homePage}
            </span>
          )}
        </div>
        <div id="navButtons">
          {!isBaseCampPage && (
            <ScrollToBottomButton onMinimizeBubbles={minimizeAllBubbles} />
          )}
          <FullscreenToggleButton />
          <LanguageSwitcher />
          <MusicPlayer />
        </div>
        <div id="hamburgerMenu">
          <HamburgerMenu
            lang={lang}
            texts={texts}
            isBaseCampPage={isBaseCampPage}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Navbar);
