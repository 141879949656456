export const panelTexts = {
  en: [
    {
      title: "Roles and Responsibilities",
      top: "65vh",
      left: "20vw",
      definitions: [
        {
          title: "Scrum Master",
          content: "The Scrum Master is a servant leader enabling growth.",
          detailedContent: `
          <p>The <span class="emphasis">Scrum Master</span> is both the guardian of the <span class="emphasis">Scrum framework</span> and a crucial pillar that helps the team and the organization grow. They are not someone who enforces rules but rather a <strong>facilitator</strong>, a <strong>coach</strong>, and sometimes a <strong>quiet leader</strong> who works behind the scenes to help the team reach its full potential.</p>
      
          <p class="subTitle">A Facilitator and Coach at the Service of the Team</p>
          <p>A key part of the role is guiding the team through the complexities of <span class="emphasis">Agile development</span> while fostering a culture of <strong>continuous improvement</strong>. The <span class="emphasis">Scrum Master</span> ensures that the team truly embraces the <span class="emphasis">Scrum values</span>—<strong>Courage</strong>, <strong>Focus</strong>, <strong>Commitment</strong>, <strong>Respect</strong>, and <strong>Openness</strong>—while learning to <strong>self-organize</strong> and take ownership of their responsibilities.</p>
      
          <p>I love the idea of not being an <strong>authoritative figure</strong> but rather someone who <strong>creates the right conditions</strong> for the team to shine. The <span class="emphasis">Scrum Master</span> doesn’t dictate; they <strong>facilitate</strong>, empowering the team to find its own solutions and fully own its way of working.</p>
      
          <p class="subTitle">Protecting the Team and Cultivating Continuous Improvement</p>
          <p>One of the most inspiring aspects of this role is its <strong>protective nature</strong>. The <span class="emphasis">Scrum Master</span> doesn’t just <strong>remove obstacles</strong>; they also ensure that <strong>external pressures</strong> don’t interfere with the team’s work.</p>
      
          <p>They foster <strong>open communication</strong> and help the team make <strong>informed decisions</strong> based on real data and feedback. To me, a great <span class="emphasis">Scrum Master</span> turns <span class="emphasis">Scrum events</span> into real <strong>learning</strong> and <strong>growth opportunities</strong>—not just routine rituals.</p>
      
          <p>What I admire most about this role is the <strong>delicate balance</strong> between <strong>support</strong> and <strong>challenge</strong>. An effective <span class="emphasis">Scrum Master</span> knows how to <strong>push the team to grow</strong> while giving them the autonomy to <strong>learn from mistakes</strong> and explore new approaches. I love the idea of creating a <strong>safe environment</strong> where <strong>failure</strong> is seen as an <strong>opportunity for learning</strong>.</p>
      
          <p class="subTitle">A Bridge Between the Team and the Organization</p>
          <p>Finally, the <span class="emphasis">Scrum Master</span> also serves as a <strong>bridge</strong> between the team and the organization. They play a crucial role in <strong>aligning the team’s efforts</strong> with <strong>strategic goals</strong> while advocating for the team’s needs with stakeholders.</p>
      
          <p>This is where the role truly comes into its own: <strong>challenging the status quo</strong>, <strong>fostering Agile awareness</strong> within the organization, and creating a context where the team can <strong>sustainably thrive</strong>.</p>
      
          <p class="subTitle">Summary</p>
          <p>In short, I see the <span class="emphasis">Scrum Master</span> as a <strong>catalyst for change</strong>—someone who both <strong>inspires</strong> and <strong>supports</strong>. It’s a role that blends <strong>vision</strong>, <strong>pragmatism</strong>, and <strong>empathy</strong>, with one ultimate responsibility: <strong>maximizing the team’s impact</strong> while fostering a <strong>culture of collaboration and continuous improvement</strong>.</p>
      `,
        },
        {
          title: "Product Owner",
          content:
            "The Product Owner maximizes product value and manages the product backlog.",
          detailedContent: `
            <p>The <span class="emphasis">Product Owner</span> is the key person responsible for <strong>maximizing the value</strong> that the product delivers. They manage the <span class="emphasis">Product Backlog</span>, ensuring that the <span class="emphasis">Development Team</span> focuses on the most <strong>impactful</strong> items.</p>
        
            <p>But their role goes beyond simple <strong>priority management</strong>—it’s about <strong>connecting</strong> the product vision with <span class="emphasis">business objectives</span>, <span class="emphasis">stakeholder expectations</span>, and the <span class="emphasis">team's efforts</span>.</p>
        
            <p>What I find essential in this role is fostering a true <strong>product culture</strong> across the organization. A great <span class="emphasis">Product Owner</span> emphasizes <strong>value creation</strong> through <span class="emphasis">iterative development</span>.</p>
        
            <p>By engaging and <strong>empowering</strong> others, the <span class="emphasis">PO</span> enables the entire team to work together toward a common goal. It’s not just about <strong>prioritization</strong>—it’s about <strong>alignment</strong>. Everyone—<span class="emphasis">stakeholders</span>, the <span class="emphasis">team</span>, and <span class="emphasis">users</span>—should understand how <strong>value</strong> is defined and delivered.</p>
        
            <p class="subTitle">A Role of Collaboration and Alignment</p>
            <p><strong>Collaboration</strong> is at the heart of the <span class="emphasis">Product Owner’s</span> role. They work closely with the <span class="emphasis">Development Team</span> and <span class="emphasis">stakeholders</span>, guiding the product’s evolution through <strong>constant feedback</strong> and <strong>data-driven insights</strong>.</p>
        
            <p>By measuring <strong>actual value</strong> and <strong>tangible outcomes</strong>, the <span class="emphasis">Product Owner</span> can make <strong>informed decisions</strong>, adjust the <span class="emphasis">Product Backlog</span>, and ensure that each iteration delivers meaningful impact.</p>
        
            <p class="subTitle">Balancing Vision and Pragmatism</p>
            <p>The <span class="emphasis">Product Owner</span> is not just a manager—they are the quintessential <strong>pragmatic visionary</strong>. They have a strategic <strong>long-term vision</strong> (<span class="emphasis">the visionary side</span>) while also acting in a <strong>realistic</strong> and <strong>actionable</strong> way (<span class="emphasis">the pragmatic side</span>).</p>
        
            <p>They skillfully balance <strong>user needs</strong>, <strong>business objectives</strong>, and <strong>team constraints</strong> while maintaining a <strong>transparent</strong> and <strong>collaborative</strong> approach. This role requires a delicate equilibrium between <strong>strategy</strong> and <strong>execution</strong>, between <strong>listening</strong> and <strong>decision-making</strong>.</p>
        
            <p class="subTitle">Summary</p>
            <p>In short, a great <span class="emphasis">Product Owner</span> is a <strong>catalyst for value</strong>, a <strong>bridge between the business and its users</strong>, and a <strong>leader</strong> who provides clear direction. Thanks to their work, the product evolves in a <strong>coherent</strong> and <strong>aligned</strong> way, delivering <span class="emphasis">increments</span> that make a real difference with every <span class="emphasis">Sprint</span>.</p>
        `,
        },

        {
          title: "Developer",
          content:
            "Developers create high-quality product increments within each sprint.",
          detailedContent: `
            <p>In a <span class="emphasis">Scrum Team</span>, <span class="emphasis">Developers</span> play a crucial role: they are the ones who transform <span class="emphasis">Product Backlog items</span> into functional <span class="emphasis">increments</span>. But their work goes far beyond writing code. They are involved in every aspect of creating a usable product—<strong>design</strong>, <strong>architecture</strong>, <strong>testing</strong>, and <strong>delivery</strong>.</p>
        
            <p><span class="emphasis">Developers</span> are versatile. They possess all the necessary skills to move the product forward without relying on external resources, allowing them to <strong>self-organize</strong> and take full ownership of <span class="emphasis">Sprint objectives</span>.</p>
            <p class="subTitle">More Than Just Code: Creators of Value</p>
            <p><span class="emphasis">Scrum</span> provides <span class="emphasis">Developers</span> with an ideal framework for solving complex problems. Through its <span class="emphasis">inspect-and-adapt</span> approach, they have the opportunity to regularly <strong>review</strong> their work and adjust their approach based on the challenges they encounter.</p>
        
            <p>Continuous <strong>feedback</strong> from the <span class="emphasis">Product Owner</span>, stakeholders, and end users helps them focus on what delivers the most <strong>value</strong> and respond effectively to real-time challenges. This interaction ensures that every delivered <span class="emphasis">increment</span> contributes to both the <strong>product</strong> and <strong>business goals</strong>.</p>
        
            <p class="subTitle">A Framework for Learning and Adapting</p>
            <p>One of <span class="emphasis">Scrum’s</span> greatest strengths for <span class="emphasis">Developers</span> is that it fosters a culture of <strong>continuous learning</strong>. By working in <span class="emphasis">Sprints</span>, they can regularly <strong>test ideas</strong>, <strong>experiment with new approaches</strong>, and <strong>refine</strong> their way of working.</p>
        
            <p>This framework is not rigid—it is designed to allow adjustments and adapt to the needs of <span class="emphasis">Developers</span>. This flexibility ensures that processes support the creation of a <strong>high-quality product</strong> without becoming a barrier to productivity.</p>
        
            <p class="subTitle">A Key Role in Agile Collaboration</p>
            <p>The importance of <strong>collaboration</strong> in <span class="emphasis">Scrum</span> is something I deeply value. <span class="emphasis">Developers</span> work closely with the <span class="emphasis">Product Owner</span> to clarify requirements and refine the <span class="emphasis">Product Backlog</span> based on <strong>feedback</strong> and <strong>progress</strong>.</p>
        
            <p>By actively participating in <span class="emphasis">Scrum events</span>—such as <span class="emphasis">Sprint Planning</span>, the <span class="emphasis">Daily Scrum</span>, and <span class="emphasis">Retrospectives</span>—they can quickly <strong>identify obstacles</strong>, <strong>adjust their approach</strong>, and <strong>improve their processes</strong>. Their engagement in these discussions makes the team more <strong>effective</strong> and <strong>responsive</strong>.</p>
        
            <p class="subTitle">Summary</p>
            <p><span class="emphasis">Developers</span> in a <span class="emphasis">Scrum Team</span> are not just <strong>executors</strong>—they are <strong>key players</strong> involved in every step of the process. Their ability to <strong>collaborate</strong>, <strong>adapt</strong>, and <strong>deliver tangible solutions</strong> makes them one of the <strong>pillars of a high-performing Agile team</strong>.</p>
        `,
        },

        {
          title: "Accountability",
          content:
            "Accountability ensures each role actively contributes to the team's success.",
          detailedContent: `
            <p>The concept of <strong>responsibility</strong> is at the core of <span class="emphasis">Scrum</span> and its effectiveness. Each role—<span class="emphasis">Product Owner</span>, <span class="emphasis">Scrum Master</span>, and <span class="emphasis">Developers</span>—carries well-defined responsibilities, and this clear distribution enables the team to stay <strong>focused</strong>, <strong>aligned</strong>, and <strong>fully committed</strong> to delivering <strong>value</strong>.</p>
        
            <p>Each role in <span class="emphasis">Scrum</span> is designed to maximize <strong>collective efficiency</strong>. A well-balanced distribution of responsibilities allows the team to operate smoothly, make <strong>informed decisions</strong>, and continuously optimize its practices.</p>
        
            <p class="subTitle">Responsibilities of Each Scrum Role</p>
            
            <p class="subTitleSmall">Product Owner</p>
            <p>In my view, the <span class="emphasis">Product Owner</span> is primarily responsible for <strong>maximizing product value</strong>. They manage the <span class="emphasis">Product Backlog</span>, <strong>prioritize tasks</strong>, and ensure the team works on what delivers the most <strong>value</strong> to the business.</p>
        
            <p>They act as the <strong>bridge</strong> between <span class="emphasis">stakeholders</span> and the <span class="emphasis">Development Team</span>, ensuring that the <span class="emphasis">product vision</span> is <strong>clear</strong>, <strong>realistic</strong>, and <strong>aligned</strong> with strategic goals.</p>
        
            <p class="subTitleSmall">Scrum Master</p>
            <p>The <span class="emphasis">Scrum Master</span>, on the other hand, is responsible for ensuring a proper <strong>understanding and application of Scrum</strong>. They <strong>protect the team</strong> from external distractions, <strong>remove obstacles</strong>, and <strong>facilitate collaboration</strong>—both within the team and across the organization.</p>
        
            <p>What I love about this role (and what motivates me as a <span class="emphasis">Professional Scrum Master</span>) is that it’s not about <strong>monitoring the team</strong> but about <strong>creating an environment</strong> where the team can <strong>self-organize</strong>, <strong>continuously improve</strong>, and <strong>perform at its best</strong>.</p>
        
            <p class="subTitleSmall">Developers</p>
            <p><span class="emphasis">Developers</span> are responsible for delivering a <span class="emphasis">Done</span> increment at the end of each <span class="emphasis">Sprint</span>. Their role extends far beyond <strong>writing code</strong>.</p>
        
            <p>They <strong>collaborate</strong> to <strong>design</strong>, <strong>develop</strong>, <strong>test</strong>, and <strong>deploy</strong> features while maintaining <strong>high-quality standards</strong>. They define their own <strong>capacity</strong> to produce work that is both <strong>functional</strong> and <strong>valuable</strong> to users.</p>
        
            <p class="subTitle">The Collective Impact of Responsibility</p>
            <p>In <span class="emphasis">Scrum</span>, responsibility is <strong>shared</strong> and embraced by every role. This encourages each team member to actively <strong>contribute</strong> to the team’s objectives while staying <strong>focused</strong> on delivering <strong>value</strong>.</p>
        
            <p>By working together and leveraging <strong>continuous feedback loops</strong>, each team member plays a role in improving both the <strong>product</strong> and the <strong>processes</strong>.</p>
        
            <p class="subTitle">Summary</p>
            <p>In short, this <strong>clear and balanced distribution</strong> of responsibilities is essential to ensuring that <span class="emphasis">Scrum</span> works as intended.</p>
        
            <p>It allows each role to <strong>focus</strong> on what truly matters while fostering <strong>effective collaboration</strong> and the <strong>incremental delivery</strong> of <span class="emphasis">value</span>.</p>
        `,
        },
        {
          title: "Self-Organizing Teams",
          content:
            "Scrum teams are autonomous and cross-functional, delivering value independently.",
          detailedContent: `
            <p><span class="emphasis">Self-organizing teams</span> are the driving force behind <span class="emphasis">Scrum’s</span> collaborative and adaptive nature. These teams take full ownership of their work, deciding for themselves how best to achieve the <span class="emphasis">Sprint</span> objectives—without requiring direct supervision from management.</p>
        
            <p>This level of <strong>autonomy</strong> enables <span class="emphasis">Scrum teams</span> to find <strong>innovative solutions</strong>, dynamically respond to <strong>challenges</strong>, and continuously optimize their <strong>processes</strong>.</p>
        
            <p>What makes these teams so effective is their ability to operate <strong>independently</strong> while staying aligned with the <span class="emphasis">product vision</span>. They have all the necessary skills to handle every aspect of <span class="emphasis">product development</span> without relying on external resources.</p>
        
            <p>Thanks to this <strong>diversity</strong>, they can deliver <span class="emphasis">high-quality increments</span> each <span class="emphasis">Sprint</span> while fully adhering to the <span class="emphasis">Definition of Done</span>. <span class="emphasis">Developers</span> collaborate to ensure the <span class="emphasis">product</span> evolves based on <strong>real-world feedback</strong> while remaining aligned with the <span class="emphasis">Product Owner’s</span> vision.</p>
        
            <p>Of course, in an ideal world, the team would have every skill needed to handle all aspects of the <span class="emphasis">product</span>. But when that’s not the case, <strong>continuous learning</strong> and <strong>knowledge sharing</strong> remain the best tools to bridge these gaps.</p>
        
            <p class="subTitle">Versatility and Accountability</p>
            <p><span class="emphasis">Self-organization</span> goes hand in hand with <strong>accountability</strong>. Each team member contributes their <strong>expertise</strong> while collaborating to <strong>solve problems</strong>, <strong>improve the product</strong>, and <strong>refine processes</strong>.</p>
        
            <p>Teams manage their own <strong>workflow</strong>, prioritize <strong>tasks</strong>, and identify <strong>improvement opportunities</strong>. In this context, the <span class="emphasis">Scrum Master</span> plays a crucial role: they support the team by <strong>removing obstacles</strong> and fostering an environment that enables <span class="emphasis">self-organization</span>—without ever dictating how the team should work.</p>
        
            <p class="subTitle">A Culture of Learning and Adaptation</p>
            <p>What I love about this model is that <strong>continuous learning</strong> and <strong>adaptability</strong> are at the heart of the approach. Through <strong>regular reflection</strong> and <strong>open communication</strong>, <span class="emphasis">self-organizing teams</span> inspect and adjust their way of working every <span class="emphasis">Sprint</span>, driving <strong>continuous improvement</strong>.</p>
        
            <p>This <strong>autonomy</strong> fosters a culture of <strong>trust</strong> and <strong>flexibility</strong>, allowing the team to <strong>quickly adapt</strong> to changes and consistently deliver <span class="emphasis">value</span>.</p>
        
            <p class="subTitle">Summary</p>
            <p>In short, <span class="emphasis">self-organizing teams</span> fully embody the <span class="emphasis">Agile</span> mindset—where <strong>collaboration</strong>, <strong>accountability</strong>, and <strong>adaptability</strong> come together to produce <strong>exceptional results</strong>.</p>
        `,
        },
      ],
    },
    {
      title: "Scrum Events",
      top: "50vh",
      left: "27vw",
      definitions: [
        {
          title: "Sprint",
          content: "A sprint is a time-boxed iteration.",
          detailedContent: `
          <p>By definition, the <span class="emphasis">Sprint</span> is the foundation of <span class="emphasis">Scrum</span>. No Scrum exists without <span class="emphasis">Sprints</span>. It is the <strong>tangible embodiment of iteration</strong> in our way of working—a fixed time period, ranging from <strong>1 to 4 weeks</strong>, during which the team focuses on creating a <span class="emphasis">functional increment</span>, ready for delivery if needed.</p>
      
          <p>It’s not just a block of time; it’s a <strong>structured workflow</strong> that brings meaning and rhythm to the process. Of course, all of this is guided by a clear <span class="emphasis">Sprint Goal</span>, which serves as a <strong>shared objective</strong>. I particularly appreciate how <strong>Ken Schwaber</strong>, co-creator of <span class="emphasis">Scrum</span>, puts it: <em>“A Sprint should be as short as possible and no shorter.”</em></p>
      
          <p class="subTitle">The Heart of the Scrum Framework</p>
          <p>To me, the <span class="emphasis">Sprint</span> perfectly embodies <strong>agility</strong>. By working in <strong>short cycles</strong>, we <strong>reduce risks</strong>, <strong>adapt to change</strong>, and, most importantly, move forward with a much <strong>clearer vision</strong>. Each delivered <span class="emphasis">increment</span> is a <strong>valuable opportunity</strong>—to <strong>gather feedback</strong>, <strong>adjust priorities</strong>, and ensure we stay aligned with <strong>real customer expectations</strong>. It’s like making a series of well-informed bets in a constantly evolving environment.</p>
      
          <p>A key element of this process is the role of the <span class="emphasis">Product Owner</span>. Thanks to them, <strong>user feedback</strong> is quickly integrated, and <strong>new insights</strong> are taken into account. In the end, each delivered <span class="emphasis">increment</span> not only meets <strong>current needs</strong> but also supports the <strong>long-term product vision</strong>.</p>
      
          <p class="subTitle">A Driver of Adaptation and Value</p>
          <p>For the team, the <span class="emphasis">Sprint</span> is also a <strong>valuable time frame</strong>. This <strong>time-boxing</strong> provides a <strong>clear structure</strong> that <strong>fosters focus</strong> on objectives, <strong>limits interruptions</strong>, and <strong>encourages innovation</strong>. It serves as a true playground for <span class="emphasis">continuous improvement</span>—both in terms of the product and how the team collaborates.</p>
      
          <p class="subTitle">Summary</p>
          <p>In short, my vision of the <span class="emphasis">Sprint</span> is that of a <strong>balance</strong>—between the <strong>discipline</strong> of delivering what we are capable of producing and the <strong>flexibility</strong> to take the time to do things right. It enables a team to <strong>deliver value consistently</strong> while <strong>minimizing risks</strong> and <strong>maximizing impact</strong>.</p>
      `,
        },
        {
          title: "Sprint Planning",
          content: "Sprint Planning defines the work for the upcoming sprint.",
          detailedContent: `
          <p><span class="emphasis">Sprint Planning</span> is where it all begins. It’s the moment when the <span class="emphasis">Scrum Team</span> gathers—whether around a physical or virtual table—to collectively set the foundation for the <span class="emphasis">Sprint</span>. It’s not just a formal meeting; it’s a <strong>crucial step</strong> to <strong>align ideas</strong> and ensure everyone is moving in the same direction.</p>
      
          <p class="subTitle">Setting the Sprint on the Right Track</p>
          <p>During this event, the team selects the <span class="emphasis">Product Backlog</span> items to be worked on in the <span class="emphasis">Sprint</span>, considering <strong>priorities</strong> and what is <strong>realistically achievable</strong>. All of this happens with the <span class="emphasis">Sprint Goal</span> in mind, which serves as a <strong>guiding thread</strong> throughout the iteration.</p>
      
          <p>What I appreciate here is that the goal isn’t to <strong>lock everything down</strong> but to establish a <strong>clear and adaptable framework</strong>. <span class="emphasis">Planning</span> isn’t a rigid constraint—it’s a <strong>guide</strong> that helps the team move forward efficiently.</p>
      
          <p class="subTitle">More Than Just Planning</p>
          <p>I also see <span class="emphasis">Sprint Planning</span> as a great illustration of the <strong>Agile mindset</strong>. As <strong>Jason Fried</strong> from Basecamp puts it: <em>“A plan is simply a guess you wrote down.”</em> A plan is nothing more than an <strong>informed hypothesis</strong>. And that’s precisely what makes this step so interesting: we start with what we <strong>know at the moment</strong> to build a plan that makes sense, while remaining <strong>ready to adjust</strong> course if necessary.</p>
      
          <p>What truly matters in this phase is that it goes <strong>beyond just planning</strong>. It’s a moment to <strong>bring the team together</strong>, <strong>align priorities</strong>, and most importantly, <strong>give meaning</strong> to everyone’s work. When I think of a successful <span class="emphasis">Sprint Planning</span>, I picture a <strong>motivated team</strong> with a <strong>shared vision</strong> and the drive to fully engage in the <span class="emphasis">Sprint</span>.</p>
      
          <p class="subTitle">Summary</p>
          <p>In short, my view of <span class="emphasis">Sprint Planning</span> is a <strong>balance between structure and flexibility</strong>—a moment to <strong>foster team cohesion</strong> and lay the foundation for a <span class="emphasis">Sprint</span> that has <strong>real meaning</strong> for everyone.</p>
      `,
        },
        {
          title: "Daily Scrum",
          content: "A brief, daily meeting for the team to synchronize.",
          detailedContent: `
          <p>The <span class="emphasis">Daily Scrum</span> is a <strong>crucial moment</strong> for <strong>open discussion</strong> within the <span class="emphasis">Scrum Team</span>. Time-boxed to <strong>15 minutes</strong>, it allows the team to meet every day to <strong>assess progress</strong>, <strong>identify obstacles</strong>, and <strong>determine next steps</strong>. It is <strong>not a formal meeting</strong> or a <strong>hierarchical status update</strong>—it is a <strong>simple, direct conversation</strong> where everyone shares what’s relevant to keep the team moving toward the <span class="emphasis">Sprint Goal</span>.</p>
      
          <p class="subTitle">Daily Alignment on the Sprint Goal</p>
          <p>The primary objective of the <span class="emphasis">Daily Scrum</span> is to ensure that everyone stays <strong>focused</strong> on the <span class="emphasis">Sprint Goal</span>. The team openly discusses:</p>
          <ul>
              <li><strong>What progress have we made?</strong></li>
              <li><strong>What obstacles are slowing us down?</strong></li>
              <li><strong>What are our next steps to move forward?</strong></li>
          </ul>
          <p>These discussions help maintain a <strong>clear view of priorities</strong> and enable the team to make <strong>immediate decisions</strong> when necessary.</p>
      
          <p class="subTitle">A Space to Address Challenges and Celebrate Wins</p>
          <p>What I appreciate about the <span class="emphasis">Daily Scrum</span> is its <strong>simplicity</strong>: it’s a moment to <strong>speak honestly</strong> about <strong>challenges</strong> while also <strong>recognizing achievements</strong>. Every team member can <strong>share their perspective</strong>, <strong>contribute ideas</strong>, or <strong>ask questions</strong>. This helps <strong>resolve blockers quickly</strong> and fosters <strong>collaboration</strong> within the team.</p>
      
          <p class="subTitle">Adaptation and Transparency in Action</p>
          <p>The <span class="emphasis">Daily Scrum</span> embodies <span class="emphasis">agility</span> by enabling <strong>continuous adaptation</strong>. It provides a <strong>daily opportunity</strong> for the team to <strong>respond to unexpected challenges</strong>, <strong>reassess priorities</strong>, and <strong>adjust plans</strong> if needed. By <strong>openly sharing information</strong>, the <span class="emphasis">Daily Scrum</span> also reinforces <strong>transparency</strong> and <strong>trust</strong> within the team.</p>
      
          <p class="subTitle">Summary</p>
          <p>In short, the <span class="emphasis">Daily Scrum</span> is a <strong>simple yet powerful event</strong>. It gives the team a chance to <strong>sync up</strong>, <strong>openly discuss successes and challenges</strong>, and stay focused on <strong>delivering value</strong>. It is a <strong>collaborative space</strong> that enhances <span class="emphasis">self-organization</span> and <strong>efficiency</strong>.</p>
      `,
        },
        {
          title: "Sprint Review",
          content: "The team presents the increment to stakeholders.",
          detailedContent: `
          <p>The <span class="emphasis">Sprint Review</span> is a <strong>key event</strong> that goes beyond simply showcasing completed work. It is a <strong>collaborative opportunity</strong> for the <span class="emphasis">Scrum Team</span> and <strong>stakeholders</strong> to examine the delivered <span class="emphasis">increment</span>, assess <strong>progress</strong>, and adjust <strong>priorities</strong> for future <span class="emphasis">Sprints</span>.</p>
      
          <p class="subTitle">A Collaborative and Strategic Event</p>
          <p>The entire <span class="emphasis">Scrum Team</span>, along with <strong>stakeholders</strong>, works together to <strong>explore ideas</strong>, <strong>identify opportunities</strong>, and <strong>refine the product’s direction</strong>. This exchange helps <strong>align the product vision</strong> with real market expectations.</p>
      
          <p>By leveraging <strong>concrete feedback</strong>, the team can refine the <span class="emphasis">Product Backlog</span> to <strong>maximize value</strong> in upcoming <span class="emphasis">Sprints</span>. This ability to <span class="emphasis">inspect</span> and <span class="emphasis">adapt</span> ensures <strong>continuous improvement</strong> and a better response to <strong>user needs</strong>.</p>
      
          <p>Additionally, the <span class="emphasis">Sprint Review</span> fosters <strong>open discussions</strong> about <strong>accomplishments</strong>, <strong>challenges faced</strong>, and <strong>next steps</strong>. It plays a crucial role in strengthening <strong>trust</strong> and <strong>alignment</strong> among all parties involved.</p>
      
          <p class="subTitle">More Than Just Validation</p>
          <p>Unlike a simple approval meeting, the <span class="emphasis">Sprint Review</span> is based on a completed (<span class="emphasis">Done</span>) and <strong>potentially releasable</strong> <span class="emphasis">increment</span>. It is <strong>not a stopping point</strong> but rather a <strong>springboard</strong> for refining the <strong>product vision</strong> and ensuring it remains aligned with <strong>user needs</strong> and <strong>strategic goals</strong>.</p>
      
          <p class="subTitle">Summary</p>
          <p>In short, the <span class="emphasis">Sprint Review</span> <strong>connects</strong> the <span class="emphasis">Scrum Team</span> and <strong>stakeholders</strong> around a <strong>shared vision</strong> and helps pave the way for each subsequent <span class="emphasis">Sprint</span> to deliver even greater <strong>value</strong>.</p>
      `,
        },
        {
          title: "Sprint Retrospective",
          content: "The team reflects on how to improve.",
          detailedContent: `
          <p>The <span class="emphasis">Sprint Retrospective</span> is a <strong>cornerstone of Scrum</strong>—almost <strong>sacred</strong>. It is the perfect opportunity for the <span class="emphasis">Scrum Team</span> to <strong>pause</strong>, <strong>reflect</strong> on how they work, and most importantly, <strong>identify concrete improvements</strong> to apply in the next <span class="emphasis">Sprint</span>.</p>
      
          <p class="subTitle">A Driver of Continuous Improvement</p>
          <p>What I’ve always appreciated about this event is that it combines <strong>reflection</strong>, <strong>action</strong>, and <strong>team dynamics</strong>. <span class="emphasis">Continuous improvement</span> here is not just a theoretical concept—every team member, from the <span class="emphasis">Scrum Master</span> to the <span class="emphasis">Product Owner</span> and <span class="emphasis">Developers</span>, actively participates in identifying <strong>meaningful and actionable solutions</strong>.</p>
          
          <p>For the <span class="emphasis">Scrum Master</span>, the <span class="emphasis">Retrospective</span> is a <strong>valuable opportunity</strong>. Through <strong>engaging and collaborative activities</strong>, they can reinforce core <span class="emphasis">Agile values</span> such as <span class="emphasis">transparency</span>, <span class="emphasis">respect</span>, and <span class="emphasis">openness</span>. These moments not only strengthen team bonds but also reinforce the pillars of agility: <span class="emphasis">transparency</span>, <span class="emphasis">inspection</span>, and <span class="emphasis">adaptation</span>.</p>
      
          <p class="subTitle">The Key Ingredients of a Successful Retrospective</p>
          <p>An effective <span class="emphasis">Sprint Retrospective</span> is built on several key elements:</p>
          <ul>
              <li><strong>Active Participation:</strong> Everyone is involved—no passive spectators. Each team member contributes their insights and perspectives.</li>
              <li><strong>Open and Inclusive Discussions:</strong> The team <strong>celebrates successes</strong>, <strong>identifies obstacles</strong>, and <strong>explores solutions</strong> in an environment of <strong>complete trust</strong>.</li>
              <li><strong>Concrete and Measurable Actions:</strong> Decisions made should not remain <strong>abstract ideas</strong> but should translate into <strong>specific, achievable improvements</strong> that are tracked in upcoming <span class="emphasis">Sprints</span>.</li>
              <li><strong>Strengthening Team Bonds:</strong> Activities are not just filler; they help align the team and foster a <strong>sense of belonging</strong>.</li>
          </ul>
      
          <p>Personally, I see the <span class="emphasis">Retrospective</span> as a <strong>true lever</strong> for <strong>learning and cohesion</strong>. It is a moment to reinforce <strong>team dynamics</strong> and <strong>naturally integrate Agile values</strong>. When done well, it goes beyond just <span class="emphasis">inspection</span> and <span class="emphasis">adaptation</span>—it becomes a <strong>powerful tool</strong> for <strong>educating the team</strong>, <strong>cultivating agility</strong>, and <strong>building a strong culture of collaboration</strong>.</p>
      
          <p class="subTitle">Summary</p>
          <p>In summary, the <span class="emphasis">Sprint Retrospective</span> is far more than a simple <strong>"review"</strong>. It is a <strong>dedicated space</strong> to <strong>turn ideas into actions</strong>, <strong>strengthen team relationships</strong>, and <strong>lay the foundation</strong> for long-term <span class="emphasis">continuous improvement</span>.</p>
      `,
        },
        {
          title: "Sprint Goal",
          content: "The Sprint Goal is the overarching objective of a sprint.",
          detailedContent: `
          <p>The <span class="emphasis">Sprint Goal</span> is a <strong>fundamental element</strong> of <span class="emphasis">Scrum</span>, yet it is often <strong>overlooked</strong>. It provides the <span class="emphasis">Scrum Team</span> with a <strong>clear overarching objective</strong> for the <span class="emphasis">Sprint</span>, defining a key priority to achieve through the selected <span class="emphasis">Product Backlog</span> items. The <span class="emphasis">Sprint Goal</span> is often compared to a <strong>compass</strong>: it helps the team <strong>navigate obstacles</strong>, <strong>adapt</strong> to new information, and <strong>stay aligned</strong> with the expected outcome.</p>
      
          <p class="subTitle">A Critical Guiding Point for the Team</p>
          <p>When <strong>challenges arise</strong> or <strong>new information emerges</strong>, the <span class="emphasis">Sprint Goal</span> serves as a <strong>reference point</strong> for decision-making. Even if specific tasks evolve, it ensures that efforts remain <strong>focused</strong> on a meaningful objective. The team continuously asks itself a crucial question: <span class="quote">"How does this decision align with the Sprint Goal?"</span></p>
      
          <p>Beyond being just a point of reference, it provides the team with <strong>clear direction</strong> and a <strong>shared objective</strong>, fostering <strong>collaboration</strong> and strengthening <strong>commitment</strong> to delivering <strong>value</strong>.</p>
      
          <p class="subTitle">A Stable and Aligned Framework</p>
          <p>The <span class="emphasis">Sprint Goal</span> connects the team’s efforts to <strong>long-term objectives</strong> or <strong>strategic releases</strong>, ensuring that each <span class="emphasis">Sprint</span> contributes to the broader <span class="emphasis">product vision</span>. It is <strong>collectively defined</strong> during <span class="emphasis">Sprint Planning</span> and remains <strong>unchanged</strong> throughout the <span class="emphasis">Sprint</span>. While individual tasks may shift, the <span class="emphasis">Sprint Goal</span> provides <strong>continuity</strong> and <strong>alignment</strong>, helping the team stay <strong>focused</strong> on what truly matters.</p>
      
          <p class="subTitle">A Lever for Transparency and Evaluation</p>
          <p>For <strong>stakeholders</strong>, the <span class="emphasis">Sprint Goal</span> brings <strong>transparency</strong> and <strong>clarity</strong> regarding the <span class="emphasis">Sprint’s</span> purpose and expected outcomes. It also serves as a <strong>benchmark</strong> for evaluating <strong>success</strong> during the <span class="emphasis">Sprint Review</span>: achieving the <span class="emphasis">Sprint Goal</span> demonstrates that the team has fulfilled its <strong>primary commitment</strong>. Conversely, failing to meet it signals the need for <strong>reflection</strong>—at the very least, during the <span class="emphasis">Sprint Retrospective</span>.</p>
      
          <p class="subTitle">Summary</p>
          <p>In summary, the <span class="emphasis">Sprint Goal</span> is <strong>not just a target</strong> for the <span class="emphasis">Sprint</span>—it is a <strong>central tool</strong> for maintaining <strong>focus</strong>, ensuring <strong>strategic alignment</strong>, and guaranteeing <strong>value delivery</strong>. It connects the team’s efforts to <strong>broader strategic objectives</strong> and ensures that each <span class="emphasis">Sprint</span> contributes meaningfully to the product’s evolution.</p>
      `,
        },
      ],
    },
    {
      title: "Scrum Artifacts",
      top: "50vh",
      left: "67vw",
      definitions: [
        {
          title: "Product Backlog Refinement / Item",
          content: "Product Backlog Items are refined and prioritized.",
          detailedContent: `
          <p class="subTitle">PBIs & PBR: The Building Blocks of the Product Backlog</p>
          <p><span class="emphasis">Product Backlog Items (PBIs)</span> are the fundamental building blocks of the <span class="emphasis">Product Backlog</span>. They define the work needed to achieve the <strong>product goals</strong> and guide the <span class="emphasis">Scrum Team’s</span> progress. Each <span class="emphasis">PBI</span> includes:</p>
      
          <ul>
              <li>A <strong>clear description</strong> of the need.</li>
              <li>A <strong>priority level</strong> based on the value it delivers.</li>
              <li>An <strong>estimate</strong> of the effort required.</li>
              <li>An <strong>evolving nature</strong>, allowing for continuous adjustments.</li>
          </ul>
      
          <p>A <span class="emphasis">PBI</span> can take various forms: <strong>features</strong> (e.g., <span class="emphasis">User Stories</span>), <strong>bugs</strong>, <strong>technical tasks</strong> (refactoring, migrations, etc.), or <strong>exploratory research</strong>. Their ability to continuously adapt reflects the <span class="emphasis">empirical</span> essence of <span class="emphasis">Scrum</span>.</p>
      
          <p class="subTitle">The Difference Between PBIs and User Stories</p>
          <p>It’s important to distinguish <span class="emphasis">PBIs</span> from <span class="emphasis">User Stories</span>. While <span class="emphasis">User Stories</span> are a specific type of <span class="emphasis">PBI</span>, focused on <strong>user needs</strong> and <strong>business value</strong>, <span class="emphasis">PBIs</span> cover a broader spectrum, including:</p>
      
          <ul>
              <li><strong>Technical improvements</strong> necessary for product stability and maintainability.</li>
              <li><strong>Bugs</strong> affecting user experience or performance.</li>
              <li><strong>Research and discovery work</strong> to validate solutions before implementation.</li>
          </ul>
      
          <p class="subTitle">The Key Role of Product Backlog Refinement (PBR)</p>
          <p><span class="emphasis">Product Backlog Refinement (PBR)</span> is a <strong>strategic activity</strong> that helps clarify and refine <span class="emphasis">PBIs</span> before they are brought into a <span class="emphasis">Sprint</span>. During <span class="emphasis">PBR</span>:</p>
      
          <ul>
              <li><span class="emphasis">PBIs</span> are broken down into <strong>smaller, actionable tasks</strong>.</li>
              <li><strong>Acceptance criteria</strong> are detailed to ensure a shared understanding.</li>
              <li>Items are <strong>estimated and prioritized</strong> based on their value and complexity.</li>
              <li>A <span class="emphasis">PBI</span> is considered <strong>“Ready”</strong> when it is <strong>clear, estimated, and fully understood</strong> by the team.</li>
          </ul>
      
          <p class="subTitle">Why Is PBR Essential?</p>
          <p><span class="emphasis">PBR</span> is a <strong>strategic enabler</strong> that enhances:</p>
      
          <ul>
              <li><strong>Transparency</strong> – Aligning <span class="emphasis">PBIs</span> with stakeholder expectations.</li>
              <li><strong>Value-driven prioritization</strong> – Ensuring every item contributes directly to product objectives.</li>
              <li><strong>Dependency reduction</strong> – Anticipating blockers to minimize obstacles during the <span class="emphasis">Sprint</span>.</li>
              <li><strong>Adaptability</strong> – Continuously integrating new learnings to keep the backlog relevant.</li>
          </ul>
      
          <p>For the team, having a <strong>clear vision</strong> of upcoming work is crucial. It influences <strong>architectural choices</strong> and <strong>technical decisions</strong>, ensuring better alignment between <span class="emphasis">product vision</span> and technical implementation.</p>
      
          <p class="subTitle">Summary</p>
          <p><span class="emphasis">PBIs</span> and <span class="emphasis">PBR</span> form a <strong>powerful combination</strong> to keep the <span class="emphasis">Product Backlog</span> clear, prioritized, and aligned with the <strong>product strategy</strong>.</p>
      
          <p>Through effective <span class="emphasis">PBR</span>, the <span class="emphasis">Scrum Team</span> can continuously <strong>deliver value</strong> while maintaining <strong>flexibility and efficiency</strong>. Proper <span class="emphasis">PBI</span> management and a well-executed <span class="emphasis">PBR</span> process are essential keys to successful <span class="emphasis">Agile development</span>.</p>
      `,
        },
        {
          title: "DOR (Definition of Ready)",
          content: "A PBI must meet certain criteria to be ready for work.",
          detailedContent: `
          <p>The <span class="emphasis">Definition of Ready (DoR)</span> is a key tool that ensures <span class="emphasis">Product Backlog Items (PBIs)</span> are fully prepared before being integrated into a <span class="emphasis">Sprint</span>. It establishes essential criteria to guarantee that the <span class="emphasis">Scrum Team</span> has all the necessary information to start working effectively.</p>
      
          <p>Its primary purpose is to <strong>reduce uncertainty</strong> and create a <strong>clear framework</strong>, enabling smooth progress aligned with product objectives.</p>
      
          <p class="subTitle">When Is a PBI “Ready”?</p>
          <p>A <span class="emphasis">PBI</span> is considered <strong>“Ready”</strong> when it meets specific essential criteria, as well as any additional criteria the team decides to include in the <span class="emphasis">DoR</span>:</p>
      
          <ul>
              <li><strong>Clear description</strong> – The <span class="emphasis">PBI</span> is understandable by the entire development team.</li>
              <li><strong>Realistic estimation</strong> – The required effort has been pragmatically assessed.</li>
              <li><strong>Reasonable size</strong> – The <span class="emphasis">PBI</span> can be completed within a single <span class="emphasis">Sprint</span>.</li>
              <li><strong>Defined value</strong> – Its impact and benefits for stakeholders are clearly identified.</li>
          </ul>
      
          <p>These criteria are not just formalities—they help optimize <span class="emphasis">Sprint Planning</span> and execution. Additionally, while primarily designed for <span class="emphasis">User Stories</span>, the <strong>INVEST framework</strong> can also be applied to <span class="emphasis">PBIs</span> to ensure quality and feasibility.</p>
      
          <p class="subTitle">The DoR and the Importance of Estimation</p>
          <p>Estimation plays a critical role in the <span class="emphasis">DoR</span> on two levels:</p>
      
          <ul>
              <li><strong>For the development team</strong>, it helps anticipate how much work can realistically fit into a <span class="emphasis">Sprint</span>.</li>
              <li><strong>For the Product Owner</strong>, it enables an evaluation of whether the expected value of a <span class="emphasis">PBI</span> justifies the resources required for its development.</li>
          </ul>
      
          <p>This process is similar to a <strong>Return on Investment (ROI) analysis</strong>, ensuring that every effort contributes strategically to the product’s objectives.</p>
      
          <p class="subTitle">A Tool for Both Short- and Long-Term Vision</p>
          <p>The <span class="emphasis">DoR</span> supports strategic planning by:</p>
      
          <ul>
              <li><strong>Anticipating future needs</strong>.</li>
              <li><strong>Prioritizing high-value backlog items</strong>.</li>
              <li><strong>Aligning product objectives</strong> with the team’s actual capacity.</li>
          </ul>
      
          <p>This approach keeps the <span class="emphasis">Product Backlog</span> structured and adaptable, ensuring efficient <span class="emphasis">Sprint</span> execution while maintaining the flexibility needed for adjustments.</p>
      
          <p class="subTitle">Summary</p>
          <p>The <span class="emphasis">Definition of Ready (DoR)</span> is a <strong>strategic enabler</strong> that ensures <span class="emphasis">PBIs</span> are fully prepared to maximize their value and minimize uncertainty.</p>
      
          <p>By facilitating planning, enhancing transparency, and aligning the <span class="emphasis">Scrum Team</span> with product priorities, the <span class="emphasis">DoR</span> enables smooth and efficient <span class="emphasis">Sprint</span> execution while keeping agility intact.</p>
      `,
        },
        {
          title: "DOD (Definition of Done)",
          content: "The DOD ensures quality and completeness of work.",
          detailedContent: `
          <p>The <span class="emphasis">Definition of Done (DoD)</span> is just a checklist of criteria. It plays a central role in ensuring <strong>product cohesion</strong> and maintaining <strong>consistent quality</strong> across delivered increments.</p>
      
          <p>It is a <strong>shared agreement</strong> within the <span class="emphasis">Scrum Team</span> that clearly defines what <strong>“done”</strong> truly means.</p>
      
          <p>This definition establishes a <strong>clear standard</strong> for <strong>quality, completeness, and integration</strong> for every increment produced during a <span class="emphasis">Sprint</span>.</p>
      
          <p class="subTitle">Concrete Criteria to Ensure Quality</p>
          <p>The <span class="emphasis">DoD</span> represents a <strong>shared understanding</strong> of the effort required to deliver a <strong>potentially shippable increment</strong>. It is not limited to technical aspects—it also covers key dimensions such as:</p>
      
          <ul>
              <li><strong>Code readability</strong> and maintainability.</li>
              <li><strong>Test automation</strong> and validation processes.</li>
              <li><strong>Security compliance</strong> and legal requirements.</li>
          </ul>
      
          <p>For example, for a digital product, the <span class="emphasis">DoD</span> might require that each increment:</p>
      
          <ul>
              <li>Successfully <strong>passes unit and integration tests</strong> (and ideally includes new unit tests for every feature).</li>
              <li>Complies with <strong>data protection regulations</strong> (e.g., GDPR in Europe, HIPAA in the U.S.).</li>
              <li>Is properly <strong>documented</strong> to ensure effective use and maintenance.</li>
          </ul>
      
          <p class="subTitle">Maintaining Product Consistency and Integration</p>
          <p>One of the fundamental roles of the <span class="emphasis">DoD</span> is to ensure that every <span class="emphasis">increment</span> integrates seamlessly with previous ones. It guarantees a <strong>cohesive, high-quality product</strong> that is always <strong>ready for release or deployment</strong>.</p>
      
          <p>In environments where multiple teams work on the same product, a <strong>common baseline DoD</strong> is essential for maintaining <strong>uniform quality</strong>. However, individual teams can extend it with additional criteria based on their specific needs.</p>
      
          <p class="subTitle">An Evolving and Adaptable Framework</p>
          <p>What makes the <span class="emphasis">DoD</span> particularly effective is that it is <strong>not static</strong>. It evolves with:</p>
      
          <ul>
              <li>Changing <strong>product and market requirements</strong>.</li>
              <li>The team’s evolving <strong>standards and best practices</strong>.</li>
              <li><strong>Lessons learned</strong> from past <span class="emphasis">Sprints</span>.</li>
          </ul>
      
          <p>By clarifying what <strong>“done”</strong> truly means, the <span class="emphasis">DoD</span> aligns expectations, enhances transparency, and minimizes <strong>technical debt</strong>.</p>
      
          <p class="subTitle">Summary</p>
          <p>The <span class="emphasis">Definition of Done</span> is a <strong>fundamental element</strong> of the <span class="emphasis">Scrum</span> framework. It guides the team toward the <strong>consistent delivery</strong> of valuable increments that are ready for use or release, all while maintaining <strong>high quality</strong>.</p>
      
          <p>As a simple process tool, the <span class="emphasis">DoD</span> is a powerful mechanism for reinforcing <strong>collaboration</strong> and <strong>trust</strong>—both within the team and with stakeholders.</p>
      `,
        },
        {
          title: "User Story",
          content:
            "User Stories describe functionality from a user perspective.",
          detailedContent: `
            <p><span class="emphasis">User Stories</span> are an effective way to <strong>capture product needs</strong> while keeping the <strong>user at the center of development</strong>. They facilitate <strong>active collaboration</strong> by creating a <strong>shared language</strong> among team members.</p>
        
            <p>What makes them so powerful is that they help teams understand <strong>why a feature matters, who benefits from it, and what impact it should have</strong>. Rather than being rigid specifications, <span class="emphasis">User Stories</span> serve as a <strong>starting point for discussions</strong>.</p>
        
            <p><span class="emphasis">User Stories</span> typically follow a clear format:</p>
        
            <blockquote class="story-format">“As a [user type], I want [feature] so that [expected benefit].”</blockquote>
        
            <p>This model is built on three key elements:</p>
            <ul>
                <li><strong>Who</strong>: The user or role concerned.</li>
                <li><strong>What</strong>: What the user wants to achieve.</li>
                <li><strong>Why</strong>: The value or benefit it provides.</li>
            </ul>
        
            <p class="subTitle">A Concrete Example</p>
            <p>Let’s take an example:</p>
        
            <blockquote class="story-format">“As a regular user, I want to save my favorite articles so I can easily find them later.”</blockquote>
        
            <p>This <span class="emphasis">User Story</span> is not a detailed specification—it is an <strong>invitation to collaborate</strong>. The specifics, such as <strong>acceptance criteria</strong>, emerge through discussions with the team and the <span class="emphasis">Product Owner</span>.</p>
        
            <p>For this Story, potential acceptance criteria could include:</p>
            <ul>
                <li>Favorite articles must be accessible from any device linked to the user’s account.</li>
                <li>The feature must allow users to remove items from their favorites if needed.</li>
            </ul>
        
            <p class="subTitle">The Difference Between User Stories and PBIs</p>
            <p><span class="emphasis">User Stories</span> are a subset of <span class="emphasis">Product Backlog Items (PBIs)</span>. Their key distinction lies in their <strong>user-centric focus</strong>:</p>
        
            <ul>
                <li><span class="emphasis">User Stories</span>: Exclusively focus on <strong>user needs</strong> and the <strong>value</strong> a feature provides.</li>
                <li><span class="emphasis">PBIs</span>: Include <strong>technical tasks, bug fixes</strong>, or <strong>exploratory work</strong> that may not directly relate to the user experience.</li>
            </ul>
        
            <p class="subTitle">User Stories in Scrum</p>
            <p><span class="emphasis">User Stories</span> encourage <strong>active collaboration</strong> and ensure a <strong>common understanding</strong> between the <span class="emphasis">Product Owner</span>, the development team, and stakeholders.</p>
        
            <p>By focusing on <strong>real user needs</strong>, they enable the <span class="emphasis">Scrum Team</span> to deliver features that provide <strong>tangible value</strong>. To ensure their effectiveness, they should follow the <span class="emphasis">INVEST</span> criteria:</p>
        
            <ul>
                <li><span class="emphasis">Independent</span>: Each Story can be developed separately.</li>
                <li><span class="emphasis">Negotiable</span>: It serves as a starting point, not a fixed specification.</li>
                <li><span class="emphasis">Valuable</span>: It delivers clear benefits to the user or business.</li>
                <li><span class="emphasis">Estimable</span>: The team can evaluate its complexity.</li>
                <li><span class="emphasis">Small enough</span>: It can be completed within a <span class="emphasis">Sprint</span>.</li>
                <li><span class="emphasis">Testable</span>: It is possible to verify whether it has been implemented correctly.</li>
            </ul>
        
            <p class="subTitle">Summary</p>
            <p><span class="emphasis">User Stories</span> are not just a practical tool—they embody a <strong>user-centered development philosophy</strong>. They <strong>clarify expectations</strong>, <strong>guide discussions</strong>, and <strong>help teams build solutions</strong> that address real user needs.</p>
        
            <p>In this way, they play a <strong>key role in aligning the team</strong> around a shared goal while keeping the focus on <strong>delivering value</strong>.</p>
        `,
        },
        {
          title: "Increment",
          content: "An increment is the sum of completed PBIs during a sprint.",
          detailedContent: `
          <p>The <span class="emphasis">Increment</span> is the <strong>tangible and functional outcome</strong> of the <span class="emphasis">Scrum Team’s</span> work at the end of each <span class="emphasis">Sprint</span>. It's a <strong>concrete result</strong> that combines the completed <span class="emphasis">Sprint items</span> with previous increments, all while adhering to the <span class="emphasis">Definition of Done (DoD)</span>.</p>
      
          <p>What makes the <span class="emphasis">Increment</span> essential is that it embodies <strong>Scrum’s empirical approach</strong>. With each iteration, it allows teams to <strong>inspect product progress</strong> and <strong>adjust priorities</strong> based on <span class="emphasis">stakeholder feedback</span>. <span class="emphasis">Sprint</span> after <span class="emphasis">Sprint</span>, the team moves closer to the <strong>product vision</strong> while staying aligned with <strong>user expectations</strong>.</p>
      
          <p class="subTitle">An Increment That’s Always Ready for Release</p>
          <p>An <span class="emphasis">Increment</span> is always <strong>potentially shippable</strong>, depending on the <span class="emphasis">Product Owner’s</span> decision. It can be <strong>released immediately</strong> or held back until a sufficient set of increments is available for a grouped deployment.</p>
      
          <p>Regardless of the release strategy, each <span class="emphasis">Increment</span> represents a <strong>solid, coherent step</strong> toward a fully functional product.</p>
      
          <p class="subTitle">A Guarantee of Value and Quality</p>
          <p>An <span class="emphasis">Increment</span> is never just a prototype or a partially completed feature. By definition, it must be:</p>
      
          <ul>
              <li><strong>Usable</strong>, ensuring that it delivers direct value to users.</li>
              <li><strong>Potentially shippable</strong>, meaning it meets the quality standards set in the <span class="emphasis">DoD</span>.</li>
              <li><strong>Of high quality</strong>, incorporating best practices in <strong>development, testing, and documentation</strong>.</li>
          </ul>
      
          <p>This level of rigor ensures a <strong>cohesive and scalable product</strong>, ready to be refined based on <span class="emphasis">feedback</span>—minimizing the risks associated with <strong>market uncertainties</strong>.</p>
      
          <p class="subTitle">Summary</p>
          <p>The <span class="emphasis">Increment</span> is the sum of the team’s work and represents the <strong>value created in each Sprint</strong> and reflects <strong>continuous progress</strong> toward the <span class="emphasis">product vision</span>.</p>
      
          <p>Through the <span class="emphasis">Increment</span>, <span class="emphasis">Scrum</span> ensures <strong>steady, reliable advancement</strong>, guaranteeing that every step forward is <strong>solid, coherent, and immediately usable</strong>. This is what makes the <span class="emphasis">Scrum</span> approach so powerful in a constantly evolving environment.</p>
      `,
        },
        {
          title: "Backlog",
          content: "The backlog is a prioritized list of work.",
          detailedContent: `
          <p>The <span class="emphasis">backlog</span> is the true <strong>engine of Agile development</strong>. It serves as a <strong>prioritized and evolving list</strong> that gathers all <strong>tasks, features, fixes</strong>, and <strong>work necessary</strong> to achieve the product’s goals.</p>
      
          <p>What always fascinates me is its <strong>living and flexible nature</strong>—it evolves based on <strong>user needs, strategic priorities</strong>, and the <strong>lessons</strong> the team learns over the course of <span class="emphasis">Sprints</span>.</p>
      
          <p>In <span class="emphasis">Scrum</span>, the <span class="emphasis">backlog</span> is divided into two main parts:</p>
      
          <ul>
              <li><span class="emphasis">Product Backlog</span> – Maintained by the <span class="emphasis">Product Owner</span>, this contains all the elements needed for the <strong>product’s long-term development</strong>. Each item, known as a <span class="emphasis">Product Backlog Item (PBI)</span>, is <strong>prioritized</strong> based on its value and can include <strong>features, fixes, or technical tasks</strong>.</li>
              <li><span class="emphasis">Sprint Backlog</span> – A subset of the <span class="emphasis">Product Backlog</span>, selected during <span class="emphasis">Sprint Planning</span>. It represents the work the team <strong>commits</strong> to completing within a specific <span class="emphasis">Sprint</span>.</li>
          </ul>
      
          <p class="subTitle">A Living and Collaborative Tool</p>
          <p>The <span class="emphasis">backlog</span> is not a static task list. It is, above all, a <strong>dynamic development roadmap</strong> that requires <strong>active and collaborative management</strong>.</p>
      
          <p>Activities like <span class="emphasis">Product Backlog Refinement</span> help <strong>clarify, detail, and prioritize</strong> items to ensure they are <strong>ready</strong> for upcoming <span class="emphasis">Sprints</span>.</p>
      
          <p class="subTitle">Key Characteristics of the Backlog</p>
          <p>Here’s what makes the <span class="emphasis">backlog</span> indispensable in <span class="emphasis">Scrum</span>:</p>
      
          <ul>
              <li><strong>Prioritization</strong> – The most <strong>important</strong> or <strong>urgent</strong> items are always at the top, ensuring that the team focuses on <strong>delivering maximum value</strong>.</li>
              <li><strong>Continuous Evolution</strong> – The <span class="emphasis">backlog</span> constantly adjusts based on <strong>stakeholder feedback, strategic changes</strong>, and <strong>insights gained during Sprints</strong>.</li>
              <li><strong>Transparency</strong> – It provides a <strong>clear view</strong> of what has been <strong>completed</strong>, what is <strong>in progress</strong>, and what <strong>remains to be done</strong>—benefiting both the team and stakeholders.</li>
          </ul>
      
          <p class="subTitle">Summary</p>
          <p>In short, the <span class="emphasis">backlog</span> is very good planning tool. It is one of the <strong>Agile compasses</strong> that guides the <span class="emphasis">Scrum Team</span> in its mission to <strong>deliver value consistently</strong>.</p>
      
          <p>By <strong>continuously organizing and adapting priorities</strong>, it enables the team to <strong>respond to evolving user and market needs</strong> while staying focused on its objectives.</p>
      `,
        },
      ],
    },
    {
      title: "Metrics and Techniques",
      top: "65vh",
      left: "75vw",
      definitions: [
        {
          title: "Velocity",
          content:
            "Velocity measures the amount of work completed in a sprint.",
          detailedContent: `
            <p><span class="emphasis">Velocity</span> is a valuable measurement tool in <span class="emphasis">Scrum</span> that helps the team understand its work pace and improve <span class="emphasis">Sprint Planning</span>. It quantifies the average number of <span class="emphasis">Product Backlog Items (PBIs)</span> completed and transformed into a <span class="emphasis">Done</span> increment during a <span class="emphasis">Sprint</span>.</p>
        
            <p>What’s essential is that <span class="emphasis">velocity</span> is an <strong>empirical reference</strong>—it guides the team’s commitments while maintaining a <strong>realistic balance</strong> between ambition and feasibility.</p>
        
            <p class="subTitle">How Does Velocity Work?</p>
            <p><span class="emphasis">Velocity</span> is based on <strong>continuous observation</strong> of results. By tracking the number of completed <span class="emphasis">PBIs</span> in each <span class="emphasis">Sprint</span>, the team gains a clear estimate of what it can realistically achieve.</p>
        
            <p>This data is particularly useful during <span class="emphasis">Sprint Planning</span>, where the team selects items for the <span class="emphasis">Sprint Backlog</span>. It helps <strong>avoid overcommitment</strong> and ensures the team takes on a realistic workload.</p>
        
            <p class="subTitle">A Planning Support Tool</p>
            <p><span class="emphasis">Velocity</span> helps the team better manage its commitments. For example:</p>
        
            <ul>
                <li><strong>If a team consistently completes 25 story points per Sprint</strong>, it can use this figure to select a similar amount of <span class="emphasis">PBIs</span> for the next <span class="emphasis">Sprint</span>.</li>
            </ul>
        
            <p>This predictability ensures <strong>better stakeholder management</strong> and prevents unrealistic commitments.</p>
        
            <p class="subTitle">An Internal Measure, Not a Performance Metric</p>
            <p>It is crucial <strong>not</strong> to use <span class="emphasis">velocity</span> as a productivity measure or to compare it across teams.</p>
        
            <ul>
                <li>Each <span class="emphasis">Scrum Team</span> has its own unique <span class="emphasis">velocity</span>, influenced by factors such as <strong>team composition, experience, and working methods</strong>.</li>
            </ul>
        
            <p><span class="emphasis">Velocity</span> is an <strong>internal tool</strong>, designed to help the team align with its own goals, free from external pressure. It should <strong>never</strong> become a constraint or a performance indicator imposed by management.</p>
        
            <p class="subTitle">Summary</p>
            <p><span class="emphasis">Velocity</span> is an <strong>empirical indicator</strong> that enhances transparency and optimizes <span class="emphasis">Sprint Planning</span>. It helps the team:</p>
        
            <ul>
                <li><strong>Understand and adjust its work pace</strong>.</li>
                <li><strong>Collaborate more effectively</strong> in managing priorities.</li>
                <li><strong>Avoid overload</strong> while honoring its commitments.</li>
            </ul>
        
            <p>By remaining focused on the team’s <strong>specific needs and capabilities</strong>, <span class="emphasis">velocity</span> helps create a <strong>balanced and productive</strong> work environment.</p>
        `,
        },
        {
          title: "Burndown Chart",
          content: "A Burndown Chart shows progress during a sprint.",
          detailedContent: `
          <p>I’ve always seen the <span class="emphasis">Burndown Chart</span> as one of the simplest yet most effective <strong>visual tools</strong> in <span class="emphasis">Scrum</span> for tracking <span class="emphasis">Sprint</span> progress. Unfortunately, it is often misused, even though it provides a <strong>clear overview</strong> of the remaining work and helps the team ensure that its pace aligns with the <span class="emphasis">Sprint Goal</span>.</p>
      
          <p class="subTitle">Structure of the Burndown Chart</p>
          <p>The <span class="emphasis">Burndown Chart</span> consists of two axes:</p>
          <ul>
              <li><strong>Vertical axis</strong> – Represents the amount of remaining work, usually measured in <strong>story points</strong> or <strong>estimated hours</strong>.</li>
              <li><strong>Horizontal axis</strong> – Represents <strong>time</strong>, often shown in <strong>days</strong> or <span class="emphasis">Sprint</span> milestones.</li>
          </ul>
          <p>At the start of the <span class="emphasis">Sprint</span>, the <span class="emphasis">Burndown Chart</span> begins with the <strong>total planned workload</strong>. As work is completed, the curve gradually declines, ideally reaching <strong>zero</strong> by the end of the <span class="emphasis">Sprint</span>.</p>
      
          <p class="subTitle">Why Use a Burndown Chart?</p>
          <p>The <span class="emphasis">Burndown Chart</span> serves several key purposes:</p>
          <ul>
              <li><strong>Transparency</strong> – Provides the team with a clear view of <span class="emphasis">Sprint</span> progress.</li>
              <li><strong>Problem detection</strong> – A flat or slow-declining curve may indicate <strong>blockers, delays,</strong> or <strong>prioritization issues</strong>.</li>
              <li><strong>Continuous improvement</strong> – By analyzing past <span class="emphasis">Burndown Charts</span>, the team can identify <strong>trends</strong> and adjust its practices to increase efficiency.</li>
          </ul>
      
          <p class="subTitle">A Concrete Example</p>
          <p><strong>Let’s take a simple scenario:</strong></p>
          <ul>
              <li>A team starts a <span class="emphasis">Sprint</span> with <strong>50 story points</strong>.</li>
              <li>It completes an average of <strong>10 points per day</strong>.</li>
              <li>The declining curve should ideally reach <strong>zero</strong> by the <span class="emphasis">Sprint</span>’s end.</li>
          </ul>
          <p>If, by <strong>Day 3</strong>, the curve stagnates, this could signal an <strong>obstacle</strong>—such as a <strong>technical issue</strong> or an <strong>unexpected dependency</strong>.</p>
      
          <p class="subTitle">Limitations of the Burndown Chart</p>
          <p>However, it’s important to recognize that the <span class="emphasis">Burndown Chart</span> has its <strong>limitations:</strong></p>
          <ul>
              <li>It does <strong>not</strong> capture work complexities, such as <strong>dependencies</strong> or unexpected challenges.</li>
              <li>It does <strong>not</strong> always reflect actual effort, especially if certain tasks take <strong>longer than anticipated</strong>.</li>
              <li>It does <strong>not replace</strong> the <span class="emphasis">Daily Scrum</span>, where the team can discuss roadblocks in more detail.</li>
          </ul>
      
          <p class="subTitle">Summary</p>
          <p>The <span class="emphasis">Burndown Chart</span> is a simple but powerful tool for tracking <span class="emphasis">Sprint</span> progress.</p>
          <ul>
              <li><strong>Enhances transparency</strong> in work progression.</li>
              <li><strong>Supports daily inspection</strong> and adaptation.</li>
              <li><strong>Helps the Scrum Team</strong> adjust its efforts to achieve the <span class="emphasis">Sprint Goal</span>.</li>
          </ul>
      
          <p>It fosters a <strong>culture of clarity and collaboration</strong>, which is essential in all <span class="emphasis">Agile</span> environment.</p>
      `,
        },
        {
          title: "Estimation",
          content: "Estimating the size of PBIs is a key planning tool.",
          detailedContent: `
          <p><span class="emphasis">Estimation</span> is a key element that helps the team assess the effort required to complete each <span class="emphasis">Product Backlog Item (PBI)</span> and effectively plan its <span class="emphasis">Sprints</span>. The goal is not to predict an exact number but to provide a <strong>relative evaluation</strong> of a PBI’s size or complexity while considering potential risks.</p>
      
          <p class="subTitle">How Does Estimation Work?</p>
          <p><span class="emphasis">Estimations</span> are often expressed in <strong>story points</strong> or another relative unit chosen by the team. This allows <span class="emphasis">PBIs</span> to be compared against each other to determine their relative size.</p>
      
          <p>The most common <span class="emphasis">Agile</span> practice is to use the <strong>Fibonacci sequence</strong> (1-2-3-5-8-13-21…), but teams can choose their own scale (<strong>bananas, tons, astronomical units</strong>…)—as long as they share a <strong>common reference point</strong>.</p>
      
          <p><strong>For example:</strong></p>
          <ul>
              <li>A <strong>complex feature</strong> might be estimated at <strong>8 points</strong>.</li>
              <li>A <strong>minor bug fix</strong> might be estimated at <strong>1 point</strong>.</li>
          </ul>
      
          <p>A recommended approach is to compare <span class="emphasis">PBIs</span> to categorize them into <strong>groups of complexity</strong>. This <strong>comparative technique</strong> helps the team visualize workload distribution and plan its efforts more effectively.</p>
      
          <p class="subTitle">The Role of Estimation in Scrum</p>
          <p><span class="emphasis">Estimation</span> serves several essential purposes:</p>
          <ul>
              <li><strong>Helps prioritization</strong> – Allows the <span class="emphasis">Product Owner</span> to rank <span class="emphasis">PBIs</span> based on their value and required effort.</li>
              <li><strong>Supports planning</strong> – During <span class="emphasis">Sprint Planning</span>, it helps the team select a realistic workload.</li>
              <li><strong>Improves predictability</strong> – Helps forecast timelines and resources needed to achieve long-term goals.</li>
          </ul>
      
          <p class="subTitle">Collaboration and Estimation Techniques</p>
          <p><span class="emphasis">Estimation</span> relies on close <strong>collaboration</strong> between the <span class="emphasis">Product Owner</span> and the <span class="emphasis">Development Team</span>. Techniques like <strong>Planning Poker</strong> foster team consensus while improving shared understanding of <span class="emphasis">PBIs</span>.</p>
      
          <p>These discussions go beyond just effort estimation—they also <strong>enhance team alignment and communication</strong>.</p>
      
          <p class="subTitle">Summary</p>
          <p><span class="emphasis">Estimation</span> is an essential practice that supports:</p>
          <ul>
              <li><strong>PBI prioritization</strong>, balancing value and complexity.</li>
              <li><strong>Sprint planning</strong>, ensuring a manageable workload.</li>
              <li><strong>Predictability</strong>, helping the team anticipate required effort.</li>
          </ul>
      
          <p><span class="emphasis">Estimation</span> is a strong <strong>strategic lever</strong> that guides the team in delivering <strong>continuous value</strong> and improving efficiency.</p>
      `,
        },
      ],
    },
    {
      title: "Principles and Pillars of Scrum",
      top: "30vh",
      left: "60vw",
      definitions: [
        {
          title: "Transparency",
          content: "Transparency is a core Scrum principle.",
          detailedContent: `
          <p><span class="emphasis">Transparency</span> is my favorite <span class="emphasis">Agile</span> value (we always have a soft spot for the underrated one). It is a <strong>fundamental condition</strong> that enables teams to navigate <strong>complexity, ambiguity,</strong> and <strong>uncertainty</strong>.</p>
          <p>It’s not just about making <strong>artifacts</strong> and <strong>processes visible</strong>—it’s about <strong>shining a light on the facts</strong>, even the uncomfortable ones that might challenge long-held beliefs.</p>
      
          <p class="subTitle">Transparency: A Key to Agility</p>
          <p><span class="emphasis">Transparency</span> can sometimes be unsettling, especially for organizations. For example:</p>
          <ul>
              <li><strong>Customer feedback</strong> might reveal that a “must-have” <span class="emphasis">Product Backlog</span> item—deemed essential by a decision-maker—actually provides no real value.</li>
              <li>It might expose <strong>gaps in work quality</strong>, even among <strong>senior team members</strong>.</li>
          </ul>
          <p>These truths, while necessary, require <strong>courage</strong> to accept and a strong sense of <span class="emphasis">psychological safety</span> to be shared without fear.</p>
      
          <p class="subTitle">Psychological Safety: A Critical Enabler</p>
          <p>For <span class="emphasis">transparency</span> to truly work, teams need to operate in an environment where they feel <strong>safe</strong>. This relies on three key elements:</p>
          <ul>
              <li><strong>Honest and open discussions</strong>, even on difficult topics.</li>
              <li><strong>Exploration of differing opinions</strong> without fear of negative repercussions.</li>
              <li>A shared belief: <span class="quote">"Everyone is doing their best with what they know at the time."</span> (Ockerman, S., & Reindl, S., <em>Mastering Professional Scrum</em>, 2019).</li>
          </ul>
          <p>What I find particularly important is the role that <strong>leaders</strong> play in this process. By leading by example and shielding teams from negative consequences linked to <span class="emphasis">transparency</span>, they create a culture where <strong>"facts are our friends."</strong></p>
          <p>With clear and reliable information, teams can <span class="emphasis">inspect</span> and <span class="emphasis">adapt</span> effectively, improving not only their work but also their collaboration.</p>
      
          <p class="subTitle">Balancing Optimism and Realism</p>
          <p><span class="emphasis">Transparency</span> also helps maintain a <strong>healthy balance</strong> between <strong>optimism</strong> and <strong>realism</strong>. Being positive is important, but that optimism must be <strong>grounded in facts</strong>—not illusions.</p>
          <p>That’s why I love the idea that <span class="emphasis">transparency</span> is an <strong>antidote to unrealistic expectations</strong>: it fosters an <strong>optimism rooted in reality</strong>, strengthening both <strong>trust</strong> and <strong>alignment</strong> within the team.</p>
      
          <p class="subTitle">Summary</p>
          <p>At its core, <span class="emphasis">transparency</span> is built on <strong>trust, courage,</strong> and <strong>genuine emotional intelligence</strong>.</p>
          <ul>
              <li>It <strong>aligns teams</strong> with the <strong>realities</strong> of their work.</li>
              <li>It <strong>drives continuous improvement</strong>.</li>
              <li>It makes <span class="emphasis">Scrum</span> <strong>truly effective</strong>.</li>
          </ul>
          <p>To me, it is one of the most <strong>powerful pillars</strong>, not just for <strong>transforming the product</strong> but also for shaping <strong>team dynamics</strong> and <strong>organizational culture</strong>.</p>
      `,
        },
        {
          title: "Inspection",
          content: "Inspection helps detect undesirable variances.",
          detailedContent: `
          <p>Now, we’re diving into the very core of <span class="emphasis">agility</span>. To me, <span class="emphasis">inspection</span> in <span class="emphasis">Scrum</span> is a true act of <strong>collective learning</strong>.</p>
      
          <p>It relies on the <span class="emphasis">Scrum Team’s</span> ability to regularly <strong>examine its results</strong>, <strong>analyze gaps</strong> between intended goals and actual outcomes, and <strong>learn</strong> from both successes and shortcomings.</p>
      
          <p><span class="emphasis">Inspection</span> encourages every team member to actively reflect on <strong>what worked well</strong> and <strong>what could have been improved</strong>. But let’s be clear—this is not about sterile criticism. Everything is based on <strong>facts</strong> and <strong>tangible feedback</strong>.</p>
      
          <p>By objectively observing results and accepting reality as it is, the team can build a solid foundation for <strong>continuous progress</strong>.</p>
      
          <p class="subTitle">A Human and Empirical Dimension</p>
          <p>What has always struck me about <span class="emphasis">inspection</span> is its deeply <strong>human aspect</strong>. It’s not always easy—it takes <strong>courage</strong> and <strong>emotional intelligence</strong> to face reality, even when it’s uncomfortable.</p>
      
          <p>But it’s also a unique opportunity to <strong>identify gaps</strong>, uncover <strong>unexpected dependencies</strong>, and find <strong>areas for improvement</strong>.</p>
      
          <p>Most importantly, it’s a chance to <strong>celebrate progress</strong> and strengthen <strong>collective trust</strong> within the team.</p>
      
          <p class="subTitle">From Analysis to Adaptation</p>
          <p>Let’s take an example: during a <span class="emphasis">Sprint Review</span>, the team realizes that some delivered features are not being used as expected by users.</p>
      
          <p>Rather than seeing this as a failure, the team collects this <strong>feedback</strong> to adjust the <span class="emphasis">Product Backlog</span>, ensuring that upcoming <span class="emphasis">increments</span> bring <strong>real value</strong>.</p>
      
          <p>In essence, <span class="emphasis">inspection</span> is about turning the <strong>unexpected</strong> into <strong>opportunities for improvement</strong>.</p>
      
          <p class="subTitle">Inspection as a Catalyst</p>
          <p>For <span class="emphasis">inspection</span> to be effective, it must take place in an environment of <strong>transparency</strong> and <strong>trust</strong>. Every team member should feel free to share observations without fear of judgment.</p>
      
          <p>This mindset—rooted in <span class="emphasis">openness</span>—is essential to fostering <strong>adaptation</strong> and <strong>continuous improvement</strong>.</p>
      
          <p class="subTitle">Summary</p>
          <p>In short, <span class="emphasis">inspection</span> is not just about <strong>control</strong>; it’s a moment of <strong>collective reflection</strong>, <strong>self-questioning</strong>, and <strong>active learning</strong>.</p>
      
          <p>It allows the team to <strong>adapt</strong>, <strong>grow</strong>, and stay aligned with the <span class="emphasis">product vision</span>. It is one of the most powerful <strong>drivers of agility</strong> in <span class="emphasis">Scrum</span>—and one of my favorite aspects of the framework.</p>
      `,
        },
        {
          title: "Adaptation",
          content: "Adaptation allows teams to adjust as needed.",
          detailedContent: `
          <p><span class="emphasis">Adaptation</span> is the very essence of <span class="emphasis">agility</span> in <span class="emphasis">Scrum</span>. It’s the ability of teams to <strong>adjust their work, priorities, and processes</strong> in response to new information, unexpected challenges, or evolving needs.</p>
      
          <p><span class="emphasis">Adaptation</span> reflects an <strong>open and proactive mindset</strong>—where every learning experience is transformed into <strong>concrete action</strong>. To me, it is closely tied to <strong>resilience</strong>: our ability to <strong>withstand, adapt, and respond effectively</strong> to the challenges around us. It is our capacity to <strong>learn from our mistakes</strong>.</p>
      
          <p>In <span class="emphasis">Scrum</span>, <span class="emphasis">adaptation</span> is driven by regular cycles of <span class="emphasis">inspection</span>. After identifying <strong>gaps</strong> or <strong>opportunities</strong>, the team analyzes the insights gained to make <strong>informed decisions</strong>.</p>
      
          <p>Whether it’s <strong>adjusting the Product Backlog</strong>, refining a work method, or changing an approach, the goal remains the same: to maintain <strong>continuous alignment</strong> between the <span class="emphasis">product</span>, <strong>user expectations</strong>, and <strong>market needs</strong>.</p>
      
          <p class="subTitle">A Collective Effort</p>
          <p><span class="emphasis">Adaptation</span> is not an individual process—it’s a <strong>team effort</strong>. The whole team participates, sharing <strong>ideas</strong>, <strong>observations</strong>, and <strong>suggestions</strong> for continuous improvement.</p>
      
          <p>But <span class="emphasis">adaptation</span> also requires <strong>courage</strong>. It can mean revisiting <strong>past decisions</strong>, abandoning a once-promising approach, or confronting <strong>uncomfortable truths</strong>.</p>
      
          <p>It’s an exercise in <strong>mutual trust</strong>, where every team member plays an <strong>active role</strong>.</p>
      
          <p class="subTitle">The Link Between Adaptation and Inspection</p>
          <p>To me, <span class="emphasis">adaptation</span> cannot exist without <span class="emphasis">inspection</span> and <span class="emphasis">transparency</span>. These pillars provide the necessary insights to evaluate the <strong>current situation</strong> and determine the <strong>right adjustments</strong>.</p>
      
          <p>This continuous cycle, rooted in <span class="emphasis">empiricism</span>, ensures that the <span class="emphasis">Scrum Team</span> moves forward in alignment while remaining <strong>flexible</strong> and <strong>effective</strong>.</p>
      
          <p class="subTitle">Summary</p>
          <p>In short, <span class="emphasis">adaptation</span> is a team’s ability to <strong>turn learnings into action</strong>, <strong>reorient its course</strong> in response to new realities, and <strong>continue delivering value</strong>—even in uncertainty.</p>
      
          <p>To me, it is a <strong>fundamental pillar</strong> that makes <span class="emphasis">Scrum</span> <strong>resilient, effective</strong>, and <strong>incredibly relevant</strong> in an ever-changing world.</p>
      `,
        },
      ],
    },
    {
      title: "Scrum Values and Agile Principles",
      top: "30vh",
      left: "34vw",
      definitions: [
        {
          title: "Empiricism",
          content:
            "Scrum is founded on empiricism, where knowledge and decision-making stem from experience.",
          detailedContent: `
            <p><span class="emphasis">Empiricism</span> is one of the fundamental pillars of <span class="emphasis">Agile methodology</span>, particularly in <span class="emphasis">Scrum</span>. It is a way of thinking and acting—the famous <strong>Agile mindset</strong>. It means making progress based on what we <strong>observe</strong> and <strong>learn</strong> directly in the field, rather than clinging to rigid forecasts. In a world where everything evolves rapidly—customer needs, markets, technical constraints—<span class="emphasis">empiricism</span> helps us stay <strong>connected to reality</strong> and make informed decisions that truly make sense.</p>
        
            <p class="subTitle">Scrum: An Empirical Framework</p>
            <p>Scrum was specifically designed to foster this <span class="emphasis">empirical approach</span> by asking a simple question: <strong>What if we stopped over-planning and instead let our results speak for themselves?</strong> Scrum invites us to embrace <strong>adaptive planning</strong> by frequently <span class="emphasis">inspecting</span> results and adjusting based on what we learn. Each <span class="emphasis">Sprint</span>, the team delivers a small, usable piece of the product—an <span class="emphasis">increment</span>. This allows teams to <strong>test ideas</strong>, validate what works, and adjust what doesn’t. Unlike traditional methods that stick to <strong>fixed plans</strong>, Scrum requires us to listen to reality, <span class="emphasis">learn from it</span>, and <strong>adapt</strong>. It is a process of <strong>learning by doing</strong>, where each iteration presents an opportunity to improve, gain a better understanding of user needs, and refine the team’s way of working.</p>
        
            <p class="subTitle">Empiricism: A Philosophy of Growth and Learning</p>
            <p>To me, <span class="emphasis">empiricism</span> represents a certain philosophy of <strong>humility</strong>. It means accepting that we don’t have all the answers from the start and that making mistakes is normal. What matters is not getting everything right the first time but <strong>continuously learning and improving</strong>. Delivering a <span class="emphasis">"Done"</span> product at the end of a <span class="emphasis">Sprint</span> isn’t just about checking a box—it’s an opportunity to <strong>reflect</strong> on what went well, what could be improved, and how to do better in the next iteration. This mindset values <strong>continuous improvement</strong> and <strong>learning</strong> over the pursuit of an often <strong>idealized and unrealistic perfection</strong>.</p>
        
            <p class="subTitle">Empiricism: A Key Lever in Agility</p>
            <p>I see <span class="emphasis">empiricism</span> as the <strong>essential lever</strong> in Agile. It helps reduce <strong>uncertainty</strong> by relying on <strong>concrete facts</strong>, on what we have actually experienced and learned. Thanks to this, we become not only <strong>reactive</strong> to change but also <strong>capable of anticipating and adapting</strong> with confidence. It enables us to <strong>stay aligned</strong> with stakeholder expectations while <strong>minimizing risks</strong> and <strong>maximizing impact</strong>.</p>
        
            <p class="subTitle">Summary</p>
            <p>In short, <span class="emphasis">empiricism</span> is a <strong>way of working</strong>, <strong>learning</strong>, and <strong>evolving</strong> together in an ever-changing world. It allows us to remain <span class="emphasis">agile</span>, <strong>continuously improve</strong>, and, most importantly, <strong>create meaningful value</strong> for users.</p>
        `,
        },
        {
          title: "Collaboration and Teamwork",
          content:
            "The success of Scrum relies on multidisciplinary collaboration, promoting responsiveness, innovation, and continuous value delivery.",
          detailedContent: `
            <p><span class="emphasis">Collaboration</span> and <span class="emphasis">teamwork</span> are at the heart of the <span class="emphasis">Scrum methodology</span>. They enable teams to tackle <strong>complex challenges</strong> while continuously improving. Working together in a <strong>fluid and adaptable</strong> way allows each member to fully contribute to the overall success of the project.</p>
        
            <p class="subTitle">Cross-Functional Teams</p>
            <p><span class="emphasis">Scrum teams</span> are made up of members with <strong>complementary skills</strong>, allowing them to complete their work <strong>autonomously</strong> without relying on external resources. This <strong>diversity</strong> is a real strength: it enables teams to <strong>respond quickly</strong> to changes and deliver <strong>high-quality products</strong>. There’s no need for everyone to be an expert in every area—what matters is leveraging each person’s specialization within a <strong>collective effort</strong>.</p>
        
            <p class="subTitle">Self-Organization</p>
            <p>In <span class="emphasis">Scrum</span>, the team is in control of its own organization. It determines the <strong>most effective way</strong> to achieve its goals. This <span class="emphasis">autonomy</span> gives each member a genuine sense of <strong>ownership</strong> and <strong>responsibility</strong> for their work. It also motivates the team to find <strong>creative solutions</strong> and adapt quickly to <strong>new challenges</strong>.</p>
        
            <p class="subTitle">Collaborative Work</p>
            <p><span class="emphasis">Collaboration</span> is litterally just working side by side. It’s about <strong>sharing ideas</strong>, solving problems together, and <strong>learning from one another</strong>. By fostering an environment of <strong>open and effective communication</strong>, <span class="emphasis">Scrum</span> helps teams react quickly and <strong>innovate</strong> more easily. <span class="emphasis">Daily stand-ups</span>, for example, ensure that everyone understands the project’s progress, reinforcing <strong>collective efficiency</strong>.</p>
        
            <p class="subTitle">Team Cohesion</p>
            <p>A team that works together over time naturally develops <strong>strong cohesion</strong> and a <strong>smooth dynamic</strong>. This stability builds <strong>trust</strong> among team members and <strong>improves communication</strong>. However, when the team changes, it’s important to make a <strong>conscious effort</strong> to integrate newcomers and maintain that <span class="emphasis">cohesion</span>.</p>
        
            <p class="subTitle">Summary</p>
            <p>In conclusion, <span class="emphasis">collaboration</span> in <span class="emphasis">Scrum</span> is built on a <strong>cross-functional</strong> and <span class="emphasis">self-organized</span> approach. It fosters <strong>innovation</strong>, strengthens <strong>communication</strong>, and enables teams to quickly <strong>adapt to change</strong>. This way of working ensures the <span class="emphasis">regular delivery of value</span> while creating a <strong>fluid, motivating, and transparent</strong> work environment.</p>
        `,
        },
        {
          title: "Iterative Development",
          content:
            "Scrum fosters continuous improvement through frequent deliveries and regular adjustments.",
          detailedContent: `
            <p><span class="emphasis">Iterative development</span> is one of the most <strong>powerful practices</strong> in <span class="emphasis">Scrum</span>. It’s a way of working that allows teams to <strong>evolve</strong> and <strong>improve</strong> their product step by step, continuously incorporating <strong>feedback</strong> and adapting along the way. Instead of trying to plan everything upfront and deliver it all at once, progress is made in small, <span class="emphasis">incremental steps</span> called <span class="emphasis">sprints</span>. Each <span class="emphasis">sprint</span> produces something tangible—a <span class="emphasis">functional increment</span>—that can be used and evaluated.</p>
        
            <p class="subTitle">A Continuous Feedback Loop</p>
            <p>What makes this approach so effective is the <strong>continuous feedback loop</strong> it creates. By delivering <span class="emphasis">increments</span> regularly, the team quickly gathers input from <strong>users</strong> or <strong>stakeholders</strong>. This not only helps <strong>refine the product</strong> but also improves the team’s <strong>work processes</strong>. Every <span class="emphasis">sprint</span> becomes an opportunity to <strong>learn</strong>, <strong>enhance</strong>, and <strong>reduce risks</strong>. By validating or correcting assumptions over time, teams prevent <strong>major issues</strong> from accumulating.</p>
        
            <p class="subTitle">A Driver of Innovation</p>
            <p>But <span class="emphasis">iterative development</span> isn’t just about <strong>solving problems</strong>—it’s also a powerful <strong>driver of innovation</strong>. Each <span class="emphasis">sprint</span> provides an opportunity to <strong>experiment</strong> with new ideas, explore <strong>different approaches</strong>, and optimize both the <strong>product</strong> and the way the team <strong>collaborates</strong>. This approach ensures that the team remains <strong>flexible</strong> and <strong>responsive</strong> while staying on track toward <strong>long-term goals</strong>.</p>
        
            <p class="subTitle">Summary</p>
            <p>In short, <span class="emphasis">iterative development</span> is like <strong>climbing a mountain</strong>: progress is made <strong>step by step</strong>, but each step brings you <strong>closer to the summit</strong>, with the freedom to <strong>adjust your path</strong> as needed.</p>
        `,
        },
        {
          title: "Scrum Values",
          content:
            "Scrum is built on five values: Courage, Focus, Commitment, Respect, and Openness.",
          detailedContent: `
            <p><span class="emphasis">Scrum values</span> are like the foundation of a house: they hold everything together and create a stable, productive environment where the team can thrive. These values foster <strong>collaboration</strong> and <strong>transparency</strong>—two essential ingredients for navigating complex environments successfully.</p>
        
            <p class="subTitle">Courage</p>
            <p><span class="emphasis">Courage</span> means facing challenges head-on, without fear of making mistakes or failing. It enables the team to make tough decisions and explore innovative solutions. But it’s not just a mindset—it’s also a culture that the <span class="emphasis">Scrum Master</span> nurtures by shielding the team from unnecessary pressures and encouraging <strong>thoughtful risk-taking</strong>. Without <span class="emphasis">courage</span>, there’s no progress!</p>
        
            <p class="subTitle">Focus</p>
            <p>In a world full of distractions, <span class="emphasis">focus</span> is the compass that keeps the team on track. It helps them stay <strong>committed to the Sprint goals</strong> and avoid spreading themselves too thin. Every <span class="emphasis">Sprint</span> is an opportunity to create value, and prioritizing what truly matters ensures <strong>steady progress</strong> in the right direction.</p>
        
            <p class="subTitle">Commitment</p>
            <p><span class="emphasis">Commitment</span> is a promise of a <strong>shared attitude</strong>. Every team member takes <strong>ownership</strong> of the goals and gives their best effort to achieve them. This <span class="emphasis">commitment</span> builds real cohesion and motivates everyone to go above and beyond expectations.</p>
        
            <p class="subTitle">Respect</p>
            <p><span class="emphasis">Respect</span> is the foundation of any healthy relationship. In a <span class="emphasis">Scrum team</span>, it means truly <strong>listening</strong>, valuing others’ ideas, and recognizing that everyone brings a unique contribution. When <span class="emphasis">respect</span> is present, discussions become more productive, and conflicts turn into <strong>opportunities for growth</strong>.</p>
        
            <p class="subTitle">Openness</p>
            <p><span class="emphasis">Openness</span> is about <strong>sharing freely</strong> and being <strong>transparent</strong> about progress and challenges. It’s essential for building <strong>trust</strong>—both within the team and with stakeholders. By being honest about difficulties, teams can address them faster and move forward together.</p>
        
            <p class="subTitle">Summary</p>
            <p>These values are what allow a <span class="emphasis">Scrum team</span> to truly thrive. Together, they create an environment where everyone can <strong>do their best work</strong> while staying <span class="emphasis">adaptable</span>, <strong>aligned with goals</strong>, and <strong>focused on delivering value</strong> throughout the project.</p>
        `,
        },
      ],
    },
  ],
  // FRANCAIS
  fr: [
    {
      title: "Rôles et Responsabilités",
      top: "65vh",
      left: "20vw",
      definitions: [
        {
          title: "Scrum Master",
          content:
            "Le Scrum Master est un servent-leader qui favorise la croissance.",
          detailedContent: `
            <p>Le <span class="emphasis">Scrum Master</span> est garant du cadre <span class="emphasis">Scrum</span> mais aussi un <strong>pilier essentiel</strong> qui permet à l’équipe et à l’organisation de progresser. Ce n’est pas quelqu’un qui impose les règles, mais un <span class="emphasis">facilitateur</span>, un <strong>coach</strong>, et parfois un <strong>leader discret</strong> qui travaille dans l’ombre pour permettre à l’équipe de donner le meilleur d’elle-même.</p>
        
            <p class="subTitle">Un facilitateur et un coach au service de l’équipe</p>
            <p>Il est fondamental qu’il aide l’équipe à <strong>naviguer dans la complexité</strong> du développement Agile, tout en cultivant une dynamique d’<span class="emphasis">amélioration continue</span>. Le <span class="emphasis">Scrum Master</span> est là pour faire en sorte que l’équipe s’approprie pleinement les <span class="emphasis">valeurs de Scrum</span> : <strong>Courage, Focus, Engagement, Respect</strong> et <strong>Ouverture</strong>, tout en apprenant à s’<span class="emphasis">auto-organiser</span> et à prendre ses responsabilités.</p>
        
            <p>J’aime cette idée de ne pas être une figure autoritaire, mais plutôt une personne qui <strong>crée les conditions propices</strong> pour que l’équipe puisse briller. Il ne dirige pas, il <span class="emphasis">facilite</span>, permettant ainsi à l’équipe de trouver ses propres solutions et de s’approprier pleinement son fonctionnement.</p>
        
            <p class="subTitle">Protéger l’équipe et cultiver l’amélioration continue</p>
            <p>Une chose qui m’inspire dans ce rôle, c’est sa capacité à <strong>protéger l’équipe</strong>. Le <span class="emphasis">Scrum Master</span> ne se contente pas d’<strong>enlever les obstacles</strong> : il veille aussi à ce que les pressions extérieures n’interfèrent pas avec le travail de l’équipe.</p>
        
            <p>Il encourage des <strong>échanges transparents</strong> et aide chacun à prendre des décisions basées sur des <strong>données concrètes</strong> et des <strong>retours d’expérience</strong>. Pour moi, un bon <span class="emphasis">Scrum Master</span>, c’est celui qui <strong>transforme les événements Scrum</strong> en de véritables moments d’apprentissage et d’évolution, et pas juste en rituels obligatoires.</p>
        
            <p>Ce que j’admire aussi dans ce rôle, c’est l’<strong>équilibre subtil entre soutien et challenge</strong>. Un <span class="emphasis">Scrum Master</span> efficace sait pousser l’équipe à se dépasser, tout en lui laissant l’autonomie nécessaire pour <strong>apprendre de ses erreurs</strong> et explorer des pistes innovantes. J’adore l’idée d’un environnement sécurisé où <strong>l’échec devient une opportunité d’apprentissage</strong>.</p>
        
            <p class="subTitle">Un lien entre l’équipe et l’organisation</p>
            <p>Enfin, le <span class="emphasis">Scrum Master</span> est aussi <strong>un pont entre l’équipe et l’organisation</strong>. Il joue un rôle clé pour <strong>aligner les efforts</strong> de l’équipe avec les objectifs stratégiques, tout en défendant les besoins de l’équipe auprès des parties prenantes.</p>
        
            <p>C’est ici que le rôle prend toute son ampleur : <strong>remettre en question le statu quo</strong>, sensibiliser l’organisation à l’<span class="emphasis">agilité</span>, et <strong>créer un contexte où l’équipe peut s’épanouir durablement</strong>.</p>
        
            <p class="subTitle">Résumé</p>
            <p>En résumé, je vois le <span class="emphasis">Scrum Master</span> comme un <strong>“catalyseur de changement”</strong>, quelqu’un qui inspire autant qu’il accompagne. C’est un rôle qui allie <strong>vision, pragmatisme et bienveillance</strong>, avec une responsabilité ultime : <strong>maximiser l’impact de l’équipe</strong> tout en favorisant une <span class="emphasis">culture de collaboration</span> et d’<span class="emphasis">amélioration continue</span>.</p>
        `,
        },
        {
          title: "Product Owner",
          content:
            "Le Product Owner maximise la valeur du produit et gère le backlog.",
          detailedContent: `
            <p>Le <span class="emphasis">Product Owner</span> est la personne clé responsable de <strong>maximiser la valeur</strong> que le produit apporte. C’est lui (ou elle) qui gère le <span class="emphasis">Product Backlog</span>, en veillant à ce que l’équipe de développement travaille en priorité sur les éléments qui ont le plus d’impact.</p>
        
            <p>Mais son rôle ne se limite pas à une simple gestion des priorités : il s’agit de connecter la <strong>vision produit</strong> avec les objectifs de l’entreprise, les attentes des <span class="emphasis">parties prenantes</span> et les efforts de l’équipe.</p>
        
            <p class="subTitle">Le garant de la valeur produit</p>
            <p>Ce que je trouve fondamental dans ce rôle, c’est de développer une véritable <strong>culture produit</strong> dans toute l’organisation. Ce qui veut dire que un bon <span class="emphasis">Product Owner</span> met l’accent sur la <span class="emphasis">création de valeur</span> à travers le <span class="emphasis">développement itératif</span>.</p>
        
            <p>En engageant et en responsabilisant les autres, le <span class="emphasis">PO</span> permet à toute l’équipe de travailler ensemble vers un <strong>objectif commun</strong>. Ce n’est pas seulement une question de <strong>priorisation</strong>, c’est avant tout une question d’<span class="emphasis">alignement</span>. Tout le monde – <span class="emphasis">parties prenantes</span>, équipe, et utilisateurs – doit comprendre comment la <strong>valeur est définie et atteinte</strong>.</p>
        
            <p class="subTitle">Un rôle de collaboration et d’alignement</p>
            <p>La collaboration est au cœur du rôle du <span class="emphasis">Product Owner</span>. Il travaille en étroite relation avec l’équipe de développement et les <span class="emphasis">parties prenantes</span>, <strong>guidant l’évolution du produit</strong> grâce à des <strong>retours constants</strong> et des données concrètes.</p>
        
            <p>En mesurant la <span class="emphasis">valeur réelle</span> et les résultats obtenus, le <span class="emphasis">Product Owner</span> peut <strong>prendre des décisions éclairées</strong>, ajuster le <span class="emphasis">Product Backlog</span>, et s’assurer que chaque itération apporte une <strong>valeur tangible</strong>.</p>
        
            <p class="subTitle">Un équilibre entre vision et pragmatisme</p>
            <p>Le <span class="emphasis">Product Owner</span> n’est pas qu’un simple gestionnaire : c’est le fameux <strong>"visionnaire pragmatique"</strong>. Il a une <span class="emphasis">vision stratégique</span> à long terme (le côté <strong>visionnaire</strong>) et une capacité à agir de manière <strong>réaliste et concrète</strong> (le côté <strong>pragmatique</strong>).</p>
        
            <p>Il sait jongler entre les besoins des utilisateurs, les objectifs de l’entreprise et les contraintes de l’équipe tout en maintenant une <strong>approche transparente et collaborative</strong>. Ce rôle est un <strong>équilibre subtil</strong> entre stratégie et action, entre écoute et prise de décision.</p>
        
            <p class="subTitle">Résumé</p>
            <p>En résumé, un bon <span class="emphasis">Product Owner</span> est un <strong>catalyseur de valeur</strong>, un <strong>créateur de lien</strong> entre l’entreprise et ses utilisateurs, et un <strong>leader</strong> qui inspire une direction claire. C’est grâce à son travail que le produit évolue de manière <span class="emphasis">cohérente et alignée</span>, livrant des <span class="emphasis">incréments</span> qui font la différence à chaque <span class="emphasis">Sprint</span>.</p>
        `,
        },
        {
          title: "Développeur(euse)",
          content:
            "Les développeurs créent des incréments de produit de haute qualité à chaque sprint.",
          detailedContent: `
            <p>Dans une équipe <span class="emphasis">Scrum</span>, les <span class="emphasis">Développeurs</span> jouent un rôle essentiel : ce sont eux qui transforment les éléments du <span class="emphasis">Product Backlog</span> en <span class="emphasis">incréments fonctionnels</span>. Mais leur travail ne se limite pas à écrire du code. Ils sont impliqués dans tous les aspects de la création d’un produit utilisable : <strong>conception, architecture, tests et livraison</strong>. </p>
        
            <p>Les <span class="emphasis">Développeurs</span> sont polyvalents. Ils possèdent toutes les compétences nécessaires pour faire avancer le produit sans dépendre de ressources extérieures, ce qui leur permet de <strong>s’auto-organiser</strong> et de prendre pleinement en charge les objectifs du <span class="emphasis">Sprint</span>.</p>
        
            <p class="subTitle">Bien plus que du code : des créateurs de valeur</p>
            <p><span class="emphasis">Scrum</span> offre aux <span class="emphasis">Développeurs</span> un cadre idéal pour <strong>résoudre des problèmes complexes</strong>. Grâce à son approche d’<span class="emphasis">inspection et d’adaptation</span>, ils ont l’opportunité de revoir régulièrement leur travail et d’ajuster leur approche en fonction des défis rencontrés.</p>
        
            <p>Les retours continus du <span class="emphasis">Product Owner</span>, des parties prenantes et des utilisateurs finaux les aident à se concentrer sur ce qui a le plus de valeur et à répondre efficacement aux défis en temps réel. Cette interaction garantit que chaque <span class="emphasis">incrément</span> livré contribue aux objectifs du produit et de l’entreprise.</p>
        
            <p class="subTitle">Un cadre pour apprendre et s’adapter</p>
            <p>L’un des atouts majeurs de <span class="emphasis">Scrum</span> pour les <span class="emphasis">Développeurs</span> est la possibilité d’évoluer dans un environnement qui favorise <strong>l’apprentissage continu</strong>. En travaillant par <span class="emphasis">Sprint</span>, ils peuvent régulièrement tester leurs idées, expérimenter de nouvelles approches et optimiser leur manière de travailler.</p>
        
            <p>Ce cadre n’est pas rigide : il est conçu pour permettre des ajustements et s’adapter aux besoins des <span class="emphasis">Développeurs</span>. Cette flexibilité garantit que les processus soutiennent la création d’un produit de qualité sans devenir un obstacle à la productivité.</p>
        
            <p class="subTitle">Un rôle clé dans la collaboration Agile</p>
            <p>L’importance de la collaboration dans <span class="emphasis">Scrum</span> est aussi un point qui me tient à cœur. Les <span class="emphasis">Développeurs</span> travaillent en étroite relation avec le <span class="emphasis">Product Owner</span> pour <strong>clarifier les exigences</strong> et ajuster le <span class="emphasis">Product Backlog</span> en fonction des retours et de l’avancement.</p>
        
            <p>En participant activement aux événements <span class="emphasis">Scrum</span> – comme le <span class="emphasis">Sprint Planning</span>, le <span class="emphasis">Daily Scrum</span> et les <span class="emphasis">Retrospectives</span> – ils peuvent rapidement identifier les obstacles, ajuster leur approche et améliorer leurs processus. Leur engagement dans ces moments d’échange permet à l’équipe d’être plus efficace et réactive.</p>
        
            <p class="subTitle">Résumé</p>
            <p>Les <span class="emphasis">Développeurs</span> dans une équipe <span class="emphasis">Scrum</span> ne sont pas "juste" des exécutants : ce sont des <strong>acteurs clés</strong>, présents à chaque étape du processus. Leur capacité à <strong>collaborer, s’adapter et livrer des solutions concrètes</strong> fait d’eux l’un des <strong>piliers</strong> d’une équipe Agile performante.</p>
        `,
        },
        {
          title: "Responsabilités",
          content:
            "Les responsabilités pour que chaque rôle contribue activement au succès de l'équipe.",
          detailedContent: `
            <p>La notion de <span class="emphasis">responsabilité</span> est au cœur de <span class="emphasis">Scrum</span> et de son efficacité. Chaque rôle – <span class="emphasis">Product Owner</span>, <span class="emphasis">Scrum Master</span> et <span class="emphasis">Développeurs</span> – porte des responsabilités bien définies, et c’est cette <strong>répartition claire</strong> qui permet à l’équipe de rester <strong>concentrée, alignée</strong> et pleinement engagée dans la <span class="emphasis">livraison de valeur</span>.</p>
        
            <p class="subTitle">Une répartition essentielle pour la performance de l’équipe</p>
            <p>Chaque rôle dans <span class="emphasis">Scrum</span> est conçu pour maximiser l’efficacité collective. Un bon équilibre des responsabilités permet à l’équipe de fonctionner de manière fluide, de prendre des décisions pertinentes et d’<strong>optimiser continuellement ses pratiques</strong>.</p>
        
            <p class="subTitle">Les responsabilités de chaque rôle Scrum</p>
        
            <p><span class="emphasis">Product Owner</span></p>
            <p>Le <span class="emphasis">Product Owner</span>, selon ma vision, est avant tout responsable de <strong>maximiser la valeur du produit</strong>. Il gère le <span class="emphasis">Product Backlog</span>, priorise les tâches et s’assure que l’équipe travaille sur ce qui apporte le plus de valeur à l’entreprise.</p>
            <p>Il fait le lien entre les <span class="emphasis">parties prenantes</span> et l’équipe de développement pour garantir que la <strong>vision du produit est claire, réaliste et alignée</strong> sur les objectifs stratégiques.</p>
        
            <p><span class="emphasis">Scrum Master</span></p>
            <p>Le <span class="emphasis">Scrum Master</span>, quant à lui, est garant de la <strong>bonne compréhension et application de Scrum</strong>. Il protège l’équipe des distractions extérieures, résout les obstacles et facilite la collaboration, que ce soit au sein de l’équipe ou avec l’organisation.</p>
            <p>Ce que j’adore dans ce rôle (et ce qui me motive en tant que <span class="emphasis">Scrum Master</span> Professionnel), c’est qu’il ne s’agit pas de "surveiller", mais de <strong>créer un environnement propice</strong> où l’équipe peut <span class="emphasis">s’auto-organiser</span>, <span class="emphasis">s’améliorer en continu</span> et donner le meilleur d’elle-même.</p>
        
            <p><span class="emphasis">Développeurs</span></p>
            <p>Les <span class="emphasis">Développeurs</span>, eux, sont responsables de <strong>livrer un incrément "Terminé"</strong> à la fin de chaque <span class="emphasis">Sprint</span>. Leur rôle dépasse largement l’écriture de code.</p>
            <p>Ils collaborent pour <strong>concevoir, développer, tester et déployer</strong> des fonctionnalités tout en maintenant des standards de qualité élevés. Ils définissent une capacité à produire un travail qui est à la fois <strong>fonctionnel et porteur de valeur</strong> pour les utilisateurs.</p>
        
            <p class="subTitle">L’impact collectif de cette responsabilité</p>
            <p>Dans <span class="emphasis">Scrum</span>, cette <strong>responsabilité est partagée</strong> et assumée par chaque rôle. Cela encourage chacun à <strong>s’impliquer activement</strong> dans les objectifs de l’équipe, tout en restant centré sur la <span class="emphasis">valeur</span>.</p>
        
            <p>En travaillant ensemble et en s’appuyant sur des <strong>boucles de feedback continues</strong>, chaque membre de l’équipe contribue à l’<span class="emphasis">amélioration continue</span> du produit et des processus.</p>
        
            <p class="subTitle">Résumé</p>
            <p>En résumé, cette répartition des <span class="emphasis">responsabilités</span>, <strong>claire et équilibrée</strong>, est essentielle pour garantir que <span class="emphasis">Scrum</span> fonctionne comme il se doit.</p>
            <p>Elle permet à chaque rôle de se <strong>concentrer sur ce qui compte vraiment</strong>, tout en favorisant une <strong>collaboration efficace</strong> et une <span class="emphasis">livraison incrémentale de valeur</span>.</p>
        `,
        },
        {
          title: "Équipes auto-organisées",
          content:
            "Les équipes Scrum sont autonomes et polyvalentes, livrant de la valeur en toute indépendance.",
          detailedContent: `
            <p>Les <span class="emphasis">équipes auto-organisées</span> sont le moteur de la nature <strong>collaborative et adaptative</strong> de <span class="emphasis">Scrum</span>. Ces équipes prennent pleinement en charge leur travail, en décidant par elles-mêmes comment atteindre au mieux les objectifs du <span class="emphasis">Sprint</span>, sans avoir besoin d’une supervision directe de la hiérarchie.</p>
        
            <p>Ce niveau d’<strong>autonomie</strong> permet aux équipes <span class="emphasis">Scrum</span> de <strong>trouver des solutions innovantes</strong>, de répondre dynamiquement aux défis et d’<strong>optimiser leurs processus</strong> de manière continue.</p>
        
            <p class="subTitle">L’auto-organisation, un moteur essentiel</p>
            <p>Ce qui est fondamental dans ces équipes, c’est leur capacité à fonctionner de manière indépendante tout en étant <strong>alignées sur la vision produit</strong>. Elles possèdent toutes les compétences nécessaires pour gérer chaque aspect du développement du produit, sans dépendre de ressources externes.</p>
        
            <p>Grâce à cette diversité, elles peuvent livrer des <span class="emphasis">incréments</span> de haute qualité à chaque <span class="emphasis">Sprint</span>, en respectant pleinement la <span class="emphasis">Définition de Terminé</span>. <strong>Les développeurs collaborent</strong> pour garantir que le produit évolue en tenant compte des retours concrets du terrain, tout en restant alignés sur la vision du <span class="emphasis">Product Owner</span>.</p>
        
            <p>Bien sûr, dans un monde parfait, l’équipe posséderait toutes les compétences nécessaires pour assumer chaque aspect du produit. Mais lorsque ce n’est pas le cas, la <strong>formation continue</strong> et le <strong>partage de connaissances</strong> restent les meilleurs outils pour combler ces lacunes.</p>
        
            <p class="subTitle">Polyvalence et responsabilisation</p>
            <p>L’<span class="emphasis">auto-organisation</span> va de pair avec la <span class="emphasis">responsabilisation</span>. Chaque membre de l’équipe apporte son expertise tout en collaborant pour <strong>résoudre les problèmes</strong>, <strong>améliorer le produit</strong> et <strong>ajuster les processus</strong>.</p>
        
            <p>Les équipes gèrent elles-mêmes leur <strong>flux de travail</strong>, priorisent les tâches et identifient les opportunités d’amélioration. Dans ce contexte, le rôle du <span class="emphasis">Scrum Master</span> est essentiel : il soutient l’équipe en éliminant les obstacles et en créant un environnement propice à l’<span class="emphasis">auto-organisation</span>, mais sans jamais dicter la manière dont l’équipe doit travailler.</p>
        
            <p class="subTitle">Une culture d’apprentissage et d’adaptation</p>
            <p>Ce que j’aime dans ce modèle, c’est que l’<strong>apprentissage continu</strong> et l’<span class="emphasis">adaptabilité</span> sont au cœur de l’approche. Grâce à une <strong>réflexion régulière</strong> et une <strong>communication ouverte</strong>, les <span class="emphasis">équipes auto-organisées</span> inspectent et ajustent leur méthode de travail à chaque <span class="emphasis">Sprint</span>, ce qui conduit à une <span class="emphasis">amélioration continue</span>.</p>
        
            <p>Cette autonomie favorise une <strong>culture de confiance et de flexibilité</strong>, permettant à l’équipe de <strong>réagir rapidement aux changements</strong> et de <span class="emphasis">livrer de la valeur</span> de manière constante.</p>
        
            <p class="subTitle">Résumé</p>
            <p>En résumé, les <span class="emphasis">équipes auto-organisées</span> incarnent pleinement l’<span class="emphasis">état d’esprit Agile</span>, où la <strong>collaboration, la responsabilisation et l’adaptabilité</strong> s’unissent pour produire des résultats exceptionnels.</p>
        `,
        },
      ],
    },
    {
      title: "Événements Scrum",
      top: "50vh",
      left: "25vw",
      definitions: [
        {
          title: "Sprint",
          content: "Un sprint est une itération limitée dans le temps.",
          detailedContent: `
          <p>Le <span class="emphasis">Sprint</span> est par définition, la base de <span class="emphasis">Scrum</span>. <strong>Pas de Scrum sans Sprint.</strong> C’est l’illustration concrète de l’<span class="emphasis">itération</span> dans notre façon de travailler. C’est cette période fixe, de <strong>1 à 4 semaines</strong>, où l’équipe se mobilise pour créer un <span class="emphasis">incrément</span> fonctionnel, prêt à être livré si besoin.</p>
      
          <p>Ce n’est pas juste un bloc de temps : c’est une <strong>dynamique de travail</strong> qui donne du sens et de la structure au processus. Tout cela, bien sûr, guidé par un <span class="emphasis">Sprint Goal</span> clair qui sert d’<strong>objectif commun</strong>. D’ailleurs, j’aime beaucoup la manière dont Ken Schwaber, le co-créateur de <span class="emphasis">Scrum</span>, l’exprime : <em>« A Sprint should be as short as possible and no shorter. »</em></p>
      
          <p class="subTitle">Le cœur du cadre Scrum</p>
          <p>À mes yeux, le <span class="emphasis">Sprint</span> incarne parfaitement l’<span class="emphasis">agilité</span>. En travaillant par cycles courts, on <strong>réduit les risques</strong>, on s’adapte aux changements, et surtout, on avance avec une <strong>vision beaucoup plus claire</strong>. Chaque <span class="emphasis">incrément</span> livré devient une <strong>opportunité précieuse</strong> : recueillir des retours, ajuster les priorités et s’assurer qu’on reste en phase avec les attentes réelles des clients. C’est un peu comme une série de <strong>petits paris éclairés</strong> dans un environnement en constante évolution.</p>
      
          <p>Un élément clé de ce processus, c’est le rôle du <span class="emphasis">Product Owner</span>. Grâce à lui (ou elle), on peut intégrer rapidement les retours des utilisateurs et prendre en compte les nouvelles données. Au final, chaque <span class="emphasis">incrément</span> livré reflète non seulement les besoins actuels, mais soutient aussi la <strong>vision produit à long terme</strong>.</p>
      
          <p class="subTitle">Un moteur d’adaptation et de valeur</p>
          <p>Pour l’équipe, le <span class="emphasis">Sprint</span>, c’est aussi une période précieuse. Ce <strong>time-boxing</strong> offre un <strong>cadre clair</strong> qui favorise la concentration sur les objectifs, limite les interruptions et encourage <strong>l’innovation</strong>. C’est un vrai terrain de jeu pour l’<span class="emphasis">amélioration continue</span>, que ce soit sur le produit ou sur la manière dont on travaille ensemble.</p>
      
          <p class="subTitle">Résumé</p>
          <p>En résumé, ma vision du <span class="emphasis">Sprint</span>, c’est celle d’un équilibre entre la <strong>rigueur</strong> de ce qu’on est capable de produire et la <strong>flexibilité</strong> pour se laisser le temps de bien faire les choses. C’est ce qui permet à une équipe de <strong>livrer régulièrement de la valeur</strong> tout en <strong>minimisant les risques</strong> et en <strong>maximisant son impact</strong>.</p>
      `,
        },
        {
          title: "Sprint Planning",
          content:
            "Le Sprint Planning définit le travail à réaliser pour le sprint.",
          detailedContent: `
            <p>Le <span class="emphasis">Sprint Planning</span> est vraiment <strong>là où tout commence</strong>. C’est le moment où l’équipe <span class="emphasis">Scrum</span> se pose autour de la table (virtuelle ou physique) pour décider, ensemble, des bases du <span class="emphasis">Sprint</span>. Ce n’est pas juste une réunion formelle : c’est une étape essentielle pour <strong>aligner les idées et avancer dans la même direction</strong>.</p>
        
            <p class="subTitle">Lancer le Sprint sur de bonnes bases</p>
            <p>Pendant cet événement, l’équipe choisit les éléments du <span class="emphasis">Product Backlog</span> à traiter dans le <span class="emphasis">Sprint</span>, en tenant compte des priorités et de ce qui est réaliste. Tout cela se fait en gardant en tête le <span class="emphasis">Sprint Goal</span>, qui joue un rôle clé en tant que <strong>fil conducteur</strong>.</p>
        
            <p>Ce qui me plaît ici, c’est qu’on ne cherche pas à tout verrouiller, mais à poser un <strong>cadre clair et adaptable</strong>. La planification n’est pas une contrainte rigide, mais un guide pour avancer de manière efficace.</p>
        
            <p class="subTitle">Plus qu’une simple planification</p>
            <p>Je vois aussi le <span class="emphasis">Sprint Planning</span> comme une belle illustration de l’<span class="emphasis">esprit agile</span>. Comme le dit Jason Fried dans <em>Basecamp</em> : <em>"A plan is simply a guess you wrote down"</em>. Un plan n’est rien de plus qu’une hypothèse. Et c’est précisément ce qui rend cette étape si intéressante : on part de ce qu’on sait au moment présent pour construire un plan qui a du sens, tout en restant prêts à <strong>ajuster le tir si nécessaire</strong>.</p>
        
            <p>Ce qui me semble vraiment important dans cette étape, c’est qu’elle dépasse la simple planification. C’est un moment pour <strong>rassembler l’équipe, aligner les priorités</strong> et surtout <strong>donner du sens au travail de chacun</strong>. Quand je pense à un <span class="emphasis">Sprint Planning</span> réussi, je vois une équipe <strong>motivée, avec une vision commune</strong> et l’envie de se lancer à fond dans le <span class="emphasis">Sprint</span>.</p>
        
            <p class="subTitle">Résumé</p>
            <p>En résumé, cette définition du <span class="emphasis">Sprint Planning</span> reflète ma manière de voir les choses : un mélange de <strong>structure et de flexibilité</strong>, un moment pour <strong>créer de la cohésion</strong> et poser les bases d’un <span class="emphasis">Sprint</span> qui a du sens pour tout le monde.</p>
        `,
        },
        {
          title: "Daily Scrum",
          content: "Une brève réunion quotidienne pour synchroniser l'équipe.",
          detailedContent: `
          <p>Le <span class="emphasis">Daily Scrum</span> est un moment essentiel de <strong>discussion ouverte</strong> pour l’équipe Scrum. <strong>Time-boxé à 15 minutes</strong>, il permet à l’équipe de <strong>se réunir chaque jour</strong> pour <strong>faire le point</strong> sur où elle en est, quels <span class="emphasis">obstacles</span> elle rencontre, et ce qu’il reste à accomplir. Ce n’est pas une <strong>réunion formelle</strong> ou une <strong>mise à jour hiérarchique</strong> : c’est une <strong>conversation simple et directe</strong>, où chacun partage ce qui est pertinent pour faire avancer l’équipe vers le <span class="emphasis">Sprint Goal</span>.</p>
      
          <p class="subTitle">Un alignement quotidien sur le Sprint Goal</p>
          <p>L’objectif principal du <span class="emphasis">Daily Scrum</span> est de s’assurer que tout le monde reste <strong>concentré</strong> sur l’objectif du Sprint. L’équipe discute librement des points suivants :</p>
          <ul>
              <li><strong>Quels progrès avons-nous réalisés ?</strong></li>
              <li><strong>Quels <span class="emphasis">obstacles</span> freinent notre avancement ?</strong></li>
              <li><strong>Quelles sont nos prochaines actions pour progresser ?</strong></li>
          </ul>
          <p>Ces échanges permettent de <strong>garder une vision claire</strong> des priorités et de <strong>prendre des décisions immédiates</strong> si nécessaire.</p>
      
          <p class="subTitle">Un espace pour parler des problèmes et des réussites</p>
          <p>Ce que j’apprécie dans le <span class="emphasis">Daily Scrum</span>, c’est sa <strong>simplicité</strong> : c’est un moment pour <strong>parler franchement</strong> des <span class="emphasis">problèmes rencontrés</span>, mais aussi pour <strong>célébrer les accomplissements</strong>. Chaque membre peut <strong>partager son point de vue</strong>, apporter des idées ou poser des questions. Cela permet de <strong>résoudre rapidement les blocages</strong> et d’<strong>encourager la collaboration</strong> au sein de l’équipe.</p>
      
          <p class="subTitle">Adaptation et transparence au quotidien</p>
          <p>Le <span class="emphasis">Daily Scrum</span> incarne <span class="emphasis">l’agilité</span> en facilitant une <strong>adaptation continue</strong>. C’est une opportunité quotidienne pour l’équipe de <strong>réagir aux imprévus</strong>, de <strong>réévaluer ses priorités</strong> et d’<strong>ajuster son plan</strong> si nécessaire. En partageant <strong>ouvertement</strong> les informations, le <span class="emphasis">Daily Scrum</span> renforce également la <span class="emphasis">transparence</span> et la <strong>confiance</strong> au sein de l’équipe.</p>
                <p class="subTitle">En résumé</p>

          <p>Pour conclure, le <span class="emphasis">Daily Scrum</span> est un moment <strong>simple mais puissant</strong>. Il donne à l’équipe l’occasion de <strong>se synchroniser</strong>, d’<strong>aborder librement les réussites et les défis</strong> et de <strong>maintenir son cap</strong> sur la livraison de valeur. C’est un <span class="emphasis">espace d’échange</span> qui favorise <span class="emphasis">l’auto-organisation</span> et <strong>l’efficacité</strong>.</p>
      `,
        },
        {
          title: "Sprint Review",
          content: "L'équipe présente l'incrément aux parties prenantes.",
          detailedContent: `
          <p>La <span class="emphasis">Sprint Review</span> est un événement clé qui dépasse la simple démonstration des travaux réalisés. C’est une occasion de <strong>collaboration directe</strong> entre l’équipe <span class="emphasis">Scrum</span> et les parties prenantes pour examiner l’<span class="emphasis">incrément</span> livré, évaluer les progrès réalisés et ajuster les priorités pour les futurs <span class="emphasis">Sprints</span>.</p>
      
          <p class="subTitle">Un événement collaboratif et stratégique</p>
          <p>Toute l’équipe <span class="emphasis">Scrum</span>, ainsi que les parties prenantes, travaillent ensemble pour <strong>explorer des idées</strong>, identifier des opportunités et ajuster la trajectoire du produit. Cet échange permet d’aligner la vision du produit sur les attentes réelles du marché.</p>
      
          <p>En s’appuyant sur des <strong>retours concrets</strong>, l’équipe peut affiner le <span class="emphasis">Product Backlog</span> pour maximiser la valeur des prochains <span class="emphasis">Sprints</span>. Cette capacité d’<span class="emphasis">inspection et d’adaptation</span> garantit une amélioration continue et une meilleure réponse aux besoins des utilisateurs.</p>
      
          <p>Enfin, la <span class="emphasis">Sprint Review</span> favorise des <strong>discussions ouvertes</strong> sur ce qui a été accompli, les obstacles rencontrés et les prochaines étapes. Elle joue un rôle essentiel pour renforcer <strong>la confiance et l’alignement</strong> entre toutes les parties.</p>
      
          <p class="subTitle">Bien plus qu’une simple validation</p>
          <p>Contrairement à une réunion de validation, la <span class="emphasis">Sprint Review</span> repose sur un <span class="emphasis">incrément</span> déjà terminé (<span class="emphasis">"Done"</span>) et potentiellement livrable. Ce n’est pas un point d’arrêt, mais <strong>un tremplin pour affiner la vision</strong> du produit et garantir qu’elle reste en phase avec les besoins des utilisateurs et les objectifs stratégiques.</p>
      
          <p class="subTitle">Résumé</p>
          <p>En résumé, la <span class="emphasis">Sprint Review</span> connecte l’équipe <span class="emphasis">Scrum</span> et les parties prenantes autour d’<strong>une vision commune</strong> et prépare la voie pour que chaque <span class="emphasis">Sprint</span> suivant apporte encore plus de valeur.</p>
      `,
        },
        {
          title: "Rétrospective de Sprint",
          content: "L'équipe réfléchit à comment s'améliorer.",
          detailedContent: `
          <p>La <span class="emphasis">rétrospective</span> est un moment incontournable de <span class="emphasis">Scrum</span>, presque sacré. C’est l’occasion idéale pour toute l’équipe <span class="emphasis">Scrum</span> de se poser, de réfléchir à son fonctionnement, et surtout, d’envisager des <strong>améliorations concrètes</strong> à appliquer dès le <span class="emphasis">Sprint</span> suivant.</p>
      
          <p class="subTitle">Un moteur d’amélioration continue</p>
          <p>Ce que j’ai toujours apprécié dans cet événement, c’est qu’il combine <strong>réflexion, action et dynamique d’équipe</strong>. On parle ici d’<span class="emphasis">amélioration continue</span>, mais pas de manière théorique : chaque membre de l’équipe – <span class="emphasis">Scrum Master</span>, <span class="emphasis">Product Owner</span> et développeurs – s’implique pour identifier des solutions pertinentes et <strong>réellement applicables</strong>.</p>
      
          <p>Pour le <span class="emphasis">Scrum Master</span>, la rétrospective est une <strong>opportunité précieuse</strong>. Grâce à des activités engageantes et collaboratives, il peut renforcer des valeurs agiles essentielles comme la <span class="emphasis">transparence</span>, le <span class="emphasis">respect</span> et l’<span class="emphasis">ouverture</span>. Ces moments permettent non seulement de créer des liens entre les membres, mais aussi d’ancrer les piliers de l’agilité : <span class="emphasis">transparence</span>, <span class="emphasis">inspection</span> et <span class="emphasis">adaptation</span>.</p>
      
          <p class="subTitle">Les ingrédients d’une rétrospective réussie</p>
          <p>Une <span class="emphasis">rétrospective</span> efficace repose sur plusieurs éléments clés :</p>
      
          <ul>
              <li><strong>Participation active :</strong> Tout le monde est impliqué, pas de spectateurs passifs. Chacun apporte sa contribution et son ressenti.</li>
              <li><strong>Discussions ouvertes et inclusives :</strong> On célèbre les réussites, on identifie les obstacles et on explore des solutions dans un climat de <strong>confiance totale</strong>.</li>
              <li><strong>Actions concrètes et mesurables :</strong> Les décisions prises ne doivent pas être de simples idées théoriques, mais des améliorations spécifiques, réalisables et suivies lors des prochains <span class="emphasis">Sprints</span>.</li>
              <li><strong>Renforcement des liens d’équipe :</strong> Les activités ne sont pas là juste pour "meubler" la réunion, elles créent un <strong>alignement et un sentiment d’appartenance</strong> au sein du groupe.</li>
          </ul>
      
          <p>Personnellement, je vois la <span class="emphasis">rétrospective</span> comme un véritable <strong>levier d’apprentissage et de cohésion</strong>. C’est un moment pour renforcer la dynamique d’équipe et intégrer les valeurs agiles de manière naturelle. Quand elle est bien menée, elle dépasse largement l’<span class="emphasis">inspection</span> et l’<span class="emphasis">adaptation</span> : elle devient un outil puissant pour <strong>éduquer l’équipe, cultiver l’agilité et construire une culture de collaboration forte</strong>.</p>
      
          <p class="subTitle">Résumé</p>
          <p>En résumé, la <span class="emphasis">rétrospective</span> est bien plus qu’un simple "bilan". C’est un espace privilégié pour <strong>transformer les idées en actions</strong>, <strong>renforcer les liens entre les membres</strong>, et poser les bases d’une <span class="emphasis">amélioration continue</span> à long terme.</p>
      `,
        },
        {
          title: "Sprint Goal",
          content: "L'objectif de sprint est l'objectif principal du sprint.",
          detailedContent: `
          <p>Le <span class="emphasis">Sprint Goal</span> est un élément fondamental de <span class="emphasis">Scrum</span>, et il est malheureusement souvent mis de côté. Il donne à l’équipe <span class="emphasis">Scrum</span> un <strong>objectif global</strong> pour le <span class="emphasis">Sprint</span>, définissant une priorité clé à atteindre grâce aux éléments sélectionnés dans le <span class="emphasis">Product Backlog</span>. On parle souvent du <span class="emphasis">Sprint Goal</span> comme <strong>une boussole</strong> : il aide l’équipe à <strong>surmonter les obstacles</strong> et à <strong>s’adapter aux nouvelles informations</strong>, tout en maintenant son <strong>alignement</strong> sur le résultat attendu.</p>
      
          <p class="subTitle">Un repère essentiel pour l’équipe</p>
          <p>Lorsque des défis surgissent ou que de nouvelles informations apparaissent, le <span class="emphasis">Sprint Goal</span> sert de <strong>point de référence</strong> pour guider les décisions. Même si les tâches évoluent, il garantit que les efforts restent concentrés sur un objectif significatif. L’équipe se pose alors une question clé : <em>“En quoi cette décision va dans le sens du <span class="emphasis">Sprint Goal</span> ?”</em></p>
      
          <p>Au-delà d’un simple repère, il offre à l’équipe une <strong>direction claire et un objectif partagé</strong>, favorisant la collaboration et renforçant <strong>l’engagement</strong> envers la livraison de valeur.</p>
      
          <p class="subTitle">Un cadre stable et aligné</p>
          <p>Le <span class="emphasis">Sprint Goal</span> connecte les efforts de l’équipe aux <strong>objectifs à long terme</strong> ou aux releases stratégiques, en s’assurant que chaque <span class="emphasis">Sprint</span> contribue à la vision globale du produit. Il est défini collectivement lors du <span class="emphasis">Sprint Planning</span> et reste constant tout au long du <span class="emphasis">Sprint</span>. Bien que les tâches spécifiques puissent évoluer, il garantit une <strong>continuité et un alignement</strong>, aidant l’équipe à rester concentrée sur l’essentiel.</p>
      
          <p class="subTitle">Un levier de transparence et d’évaluation</p>
          <p>Pour les parties prenantes, le <span class="emphasis">Sprint Goal</span> apporte <strong>transparence et clarté</strong> sur l’objectif et les résultats attendus du <span class="emphasis">Sprint</span>. Il devient également une base pour évaluer le succès lors de la <span class="emphasis">Sprint Review</span> : atteindre le <span class="emphasis">Sprint Goal</span> prouve que l’équipe a respecté son <strong>engagement principal</strong>. À l’inverse, ne pas l’atteindre implique une remise en question, à analyser a minima en rétrospective.</p>
      
          <p class="subTitle">Résumé</p>
          <p>En résumé, le <span class="emphasis">Sprint Goal</span> n’est pas simplement une cible pour le <span class="emphasis">Sprint</span>. C’est un <strong>outil central</strong> pour maintenir le focus, assurer l’<strong>alignement stratégique</strong> et garantir la <strong>livraison de valeur</strong>. Il connecte les efforts de l’équipe à des objectifs stratégiques plus larges et s’assure que chaque <span class="emphasis">Sprint</span> contribue significativement à l’évolution du produit.</p>
      `,
        },
      ],
    },
    {
      title: "Artifacts Scrum",
      top: "50vh",
      left: "67vw",
      definitions: [
        {
          title: "PBR/PBI",
          content: "Les éléments du backlog sont affinés et priorisés.",
          detailedContent: `
          <p class="subTitle">Les PBIs : les briques du Product Backlog</p>
          <p>Les <span class="emphasis">Product Backlog Items (PBIs)</span> sont les <strong>briques fondamentales</strong> du <span class="emphasis">Product Backlog</span>. Ils définissent le travail nécessaire pour atteindre les objectifs du produit et guident l’équipe <span class="emphasis">Scrum</span> dans sa progression. Chaque PBI contient :</p>
      
          <ul>
              <li>Une <strong>description</strong> claire du besoin.</li>
              <li>Une <strong>priorité</strong> définie en fonction de la <span class="emphasis">valeur</span> qu’il apporte.</li>
              <li>Une <strong>estimation</strong> de l’effort nécessaire.</li>
              <li>Une nature <span class="emphasis">évolutive</span>, permettant des ajustements continus.</li>
          </ul>
      
          <p>Un PBI peut prendre plusieurs formes : <strong>fonctionnalités</strong> (ex. : <span class="emphasis">User Stories</span>), <strong>bugs</strong> à corriger, <strong>tâches techniques</strong> (refactoring, migration…), ou encore <strong>études exploratoires</strong>. Ce qui les rend uniques, c’est leur capacité à s’adapter en permanence, reflétant ainsi l’essence même de l’<span class="emphasis">approche empirique</span> de <span class="emphasis">Scrum</span>.</p>
      
          <p class="subTitle">Différence entre PBIs et User Stories</p>
          <p>Il est important de ne pas confondre <span class="emphasis">PBIs</span> et <span class="emphasis">User Stories</span>. Les <strong>User Stories</strong> sont un type spécifique de PBI, centré sur les besoins des utilisateurs et la valeur métier. Les PBIs, quant à eux, couvrent un spectre plus large et peuvent inclure :</p>
      
          <ul>
              <li>Des <strong>améliorations techniques</strong> nécessaires à la stabilité et à la maintenabilité du produit.</li>
              <li>Des <strong>bugs</strong> impactant l’expérience utilisateur ou les performances.</li>
              <li>Des <strong>recherches et études</strong> pour valider une solution avant son implémentation.</li>
          </ul>
      
          <p class="subTitle">Le rôle clé du Product Backlog Refinement (PBR)</p>
          <p>Le <span class="emphasis">Product Backlog Refinement (PBR)</span> est une activité stratégique permettant d’<strong>affiner</strong> et de <strong>clarifier</strong> les PBIs avant leur intégration dans un <span class="emphasis">Sprint</span>. C’est lors du PBR que :</p>
      
          <ul>
              <li>Les PBIs sont décomposés en tâches plus petites pour devenir <strong>actionnables</strong>.</li>
              <li>Les critères d’acceptation sont détaillés pour garantir une compréhension commune.</li>
              <li>Les éléments sont estimés et priorisés selon leur <span class="emphasis">valeur</span> et leur complexité.</li>
          </ul>
      
          <p>Un PBI est considéré comme "<strong>Ready</strong>" lorsqu’il est suffisamment clair, estimé et compris par toute l’équipe.</p>
      
          <p class="subTitle">Pourquoi le PBR est essentiel ?</p>
          <p>Le PBR est un levier stratégique qui améliore :</p>
      
          <ul>
              <li><span class="emphasis">Transparence</span> : Il aligne les PBIs avec les attentes des parties prenantes.</li>
              <li><span class="emphasis">Alignement sur la valeur</span> : Chaque élément doit contribuer directement aux objectifs du produit.</li>
              <li><span class="emphasis">Réduction des dépendances</span> : En anticipant les obstacles, l’équipe minimise les blocages durant le Sprint.</li>
              <li><span class="emphasis">Adaptabilité</span> : Le PBR permet d’intégrer les derniers apprentissages et de maintenir un backlog toujours pertinent.</li>
          </ul>
      
          <p>Pour l'équipe, avoir une vision claire des travaux futurs est essentiel. Cela influence les choix d’<strong>architecture</strong> et les décisions techniques, garantissant un meilleur alignement entre la vision produit et la mise en œuvre technique.</p>
      
          <p class="subTitle">Résumé</p>
          <p>Les <span class="emphasis">PBIs</span> et le <span class="emphasis">PBR</span> forment une combinaison puissante pour assurer que le <span class="emphasis">Product Backlog</span> reste clair, priorisé et aligné avec la stratégie produit.</p>
      
          <p>Grâce à ce processus, l’équipe <span class="emphasis">Scrum</span> peut <strong>livrer de la valeur en continu</strong> tout en conservant une flexibilité et une efficacité maximales. Une gestion rigoureuse des PBIs et un <strong>PBR efficace</strong> sont donc des clés essentielles pour un développement agile réussi.</p>
      `,
        },
        {
          title: "DOR (Définition de prêt)",
          content:
            "Un PBI doit répondre à certains critères pour être prêt à être travaillé.",
          detailedContent: `
            <p>La <span class="emphasis">Definition of Ready (DoR)</span> est un outil clé permettant de garantir que les <span class="emphasis">Product Backlog Items (PBIs)</span> sont prêts à être intégrés dans un <span class="emphasis">Sprint</span>. Elle définit des <strong>critères essentiels</strong> assurant que l’équipe Scrum dispose de toutes les informations nécessaires pour commencer à travailler efficacement.</p>
        
            <p>Son rôle principal est de <strong>réduire les incertitudes</strong> et de créer un cadre clair pour que le travail puisse avancer de manière fluide et alignée sur les objectifs du produit.</p>
        
            <p class="subTitle">Quand un PBI est-il "Ready" ?</p>
            <p>Un <span class="emphasis">PBI</span> est considéré comme "<strong>Ready</strong>" lorsqu’il remplit certains critères indispensables ainsi que tous les critères que l'équipe souhaite rajouter au DOR :</p>
        
            <ul>
                <li><strong>Une description claire</strong> : Le PBI est compréhensible par toute l’équipe de développement.</li>
                <li><strong>Une estimation réaliste</strong> : L’effort requis est évalué de manière pragmatique.</li>
                <li><strong>Une taille raisonnable</strong> : Le PBI peut être complété en un seul <span class="emphasis">Sprint</span>.</li>
                <li><strong>Une valeur définie</strong> : Son impact et son bénéfice pour les parties prenantes sont clairement identifiés.</li>
            </ul>
        
            <p>Ces critères ne sont pas qu’une formalité : ils permettent d’optimiser la <span class="emphasis">planification</span> et l’exécution des Sprints. De plus, bien que principalement conçue pour les <span class="emphasis">User Stories</span>, la logique INVEST peut aussi être appliquée aux PBIs.</p>
        
            <p class="subTitle">La DoR et l’importance de l’estimation</p>
            <p>L’<span class="emphasis">estimation</span> est un élément central de la DoR. Elle joue un rôle à deux niveaux :</p>
        
            <ul>
                <li> Pour l’équipe de développement, elle aide à anticiper la quantité de travail réalisable dans un Sprint.</li>
                <li> Pour le <span class="emphasis">Product Owner</span>, elle permet d’évaluer si la valeur attendue d’un PBI justifie les ressources nécessaires à son développement.</li>
            </ul>
        
            <p>Cette réflexion s’apparente à une <strong>analyse du ROI</strong> (Return on Investment), permettant de s’assurer que chaque effort investi aligne l’équipe sur les objectifs stratégiques du produit.</p>
        
            <p class="subTitle">Un outil pour une vision à court et long terme</p>
            <p>La <span class="emphasis">DoR</span> contribue à la <strong>planification stratégique</strong> en :</p>
        
            <ul>
                <li> <strong>Anticipant</strong> les besoins futurs.</li>
                <li> <strong>Priorisant</strong> les éléments à forte valeur ajoutée.</li>
                <li> <strong>Alignant</strong> les objectifs du produit avec la capacité réelle de l’équipe.</li>
            </ul>
        
            <p>Grâce à cette approche, le backlog reste structuré et évolutif, garantissant une exécution efficace des Sprints tout en maintenant une flexibilité nécessaire à l’adaptation.</p>
        
            <p class="subTitle">Résumé</p>
            <p>La <span class="emphasis">Definition of Ready (DoR)</span> est un <strong>levier stratégique</strong> qui assure que les PBIs sont préparés pour maximiser leur <span class="emphasis">valeur</span> et minimiser les <span class="emphasis">incertitudes</span>.</p>
        
            <p>Elle <strong>facilite la planification</strong>, <strong>renforce la transparence</strong> et <strong>aligne l’équipe Scrum</strong> sur les priorités du produit, tout en permettant une exécution fluide et efficace des Sprints.</p>
        `,
        },
        {
          title: "DOD (Définition de terminé)",
          content: "La DOD garantit la qualité et l'achèvement du travail.",
          detailedContent: `
          <p>La <span class="emphasis">Definition of Done (DoD)</span> est juste une simple liste de critères. Elle joue un rôle central dans la <strong>cohésion du produit</strong> et garantit une <strong>qualité homogène</strong> des incréments livrés. Plus qu’un document, c’est une <strong>entente commune</strong> au sein de l’équipe <span class="emphasis">Scrum</span>, définissant précisément ce que signifie <strong>“terminé”</strong>.</p>
      
          <p>Cette définition assure un <span class="emphasis">standard clair</span> en matière de <strong>qualité, complétude et intégrabilité</strong> pour chaque incrément produit pendant un Sprint.</p>
      
          <p class="subTitle">Des critères concrets pour assurer la qualité</p>
          <p>La <span class="emphasis">DoD</span> représente une <strong>compréhension partagée</strong> des efforts nécessaires pour livrer un <span class="emphasis">incrément potentiellement livrable</span>. Elle ne se limite pas aux aspects techniques : elle inclut des dimensions clés comme la <strong>lisibilité du code, l’automatisation des tests</strong>, la <strong>conformité aux normes de sécurité</strong> ou encore les <strong>exigences légales</strong>.</p>
      
          <p>Par exemple, pour un produit numérique, la <span class="emphasis">DoD</span> pourrait exiger que chaque incrément :</p>
      
          <ul>
              <li>Passe avec succès les <strong>tests unitaires et d’intégration</strong> (et idéalement que des tests unitaires soient développés pour chaque nouvelle fonctionnalité).</li>
              <li>Respecte les <strong>normes de protection des données</strong> (<span class="emphasis">RGPD</span> en Europe, <span class="emphasis">HIPAA</span> aux USA).</li>
              <li>Soit <strong>documenté</strong> pour assurer une utilisation et une maintenance efficace.</li>
          </ul>
      
          <p class="subTitle">Maintenir la cohérence et l’intégration du produit</p>
          <p>L’un des rôles fondamentaux de la <span class="emphasis">DoD</span> est de garantir que chaque incrément s’intègre <strong>harmonieusement</strong> avec ceux déjà réalisés. Elle assure un <strong>produit cohérent et de haute qualité</strong>, toujours prêt à être livré ou publié.</p>
      
          <p>Dans des contextes où plusieurs équipes travaillent sur le même produit, une <strong>base commune</strong> de <span class="emphasis">DoD</span> est essentielle pour garantir une <strong>qualité homogène</strong>. Cependant, chaque équipe peut y ajouter des critères spécifiques en fonction de son contexte.</p>
      
          <p class="subTitle">Un cadre évolutif et adaptable</p>
          <p>Ce qui rend la <span class="emphasis">DoD</span> particulièrement efficace, c’est qu’elle <strong>n’est pas figée</strong>. Elle évolue avec :</p>
          <ul>
              <li>Les <strong>exigences du produit</strong> et du marché.</li>
              <li>Les <strong>standards et pratiques de l’équipe</strong>.</li>
              <li>Les <strong>leçons apprises au fil des Sprints</strong>.</li>
          </ul>
      
          <p>En clarifiant ce que <strong>“terminé”</strong> signifie réellement, la <span class="emphasis">DoD</span> aligne les attentes, améliore la <span class="emphasis">transparence</span> et minimise la <span class="emphasis">dette technique</span>.</p>
      
          <p class="subTitle">Résumé</p>
          <p>La <span class="emphasis">Definition of Done</span> est un <strong>élément fondamental</strong> du cadre <span class="emphasis">Scrum</span>. Elle guide l’équipe vers une <strong>livraison régulière d’incréments de valeur</strong>, prêts à être utilisés ou publiés, tout en garantissant un <strong>haut niveau de qualité</strong>.</p>
      
          <p>Tel un simple <strong>outil de processus</strong>, la <span class="emphasis">DoD</span> est un moyen puissant de renforcer la <strong>collaboration et la confiance</strong> au sein de l’équipe et avec les parties prenantes.</p>
      `,
        },
        {
          title: "User Story",
          content:
            "Les user stories décrivent des fonctionnalités du point de vue de l'utilisateur.",
          detailedContent: `
            <p>Les <span class="emphasis">User Stories</span> sont un moyen efficace de capturer les <strong>besoins du produit</strong> tout en plaçant l’utilisateur au cœur du développement. Elles facilitent la <span class="emphasis">collaboration active</span> en créant un <strong>langage commun</strong> entre les membres de l’équipe.</p>
        
            <p>Ce qui fait leur force, c’est qu’elles permettent de comprendre <strong>pourquoi</strong> une fonctionnalité est importante, <strong>à qui</strong> elle profite, et <strong>quel impact</strong> elle doit avoir. Elles servent ainsi de <strong>point de départ aux discussions</strong>, plutôt que d’être une spécification figée.</p>
        
            <p class="subTitle">Une structure simple et efficace</p>
            <p>Les <span class="emphasis">User Stories</span> suivent généralement un format clair :</p>
        
            <blockquote>« En tant que [type d’utilisateur], je veux [fonctionnalité] afin de [bénéfice attendu]. »</blockquote>
        
            <p>Ce modèle repose sur trois piliers :</p>
            <ul>
                <li><strong>Qui</strong> : L’utilisateur ou le rôle concerné.</li>
                <li><strong>Quoi</strong> : Ce que l’utilisateur souhaite accomplir.</li>
                <li><strong>Pourquoi</strong> : La valeur ou le bénéfice que cela apporte.</li>
            </ul>
        
            <p class="subTitle">Exemple concret</p>
            <p>Prenons un exemple :</p>
        
            <blockquote>« En tant qu’utilisateur régulier, je veux pouvoir enregistrer mes articles favoris afin de les retrouver facilement plus tard. »</blockquote>
        
            <p>Cette <span class="emphasis">User Story</span> n’est pas une spécification détaillée, mais une <strong>invitation à collaborer</strong>. Les détails, comme les critères d’acceptation, émergent au fil des discussions avec l’équipe et le <span class="emphasis">Product Owner</span>.</p>
        
            <p>Par exemple, pour cette Story :</p>
            <ul>
                <li>Les articles favoris doivent être accessibles depuis <strong>n’importe quel appareil</strong> connecté au compte.</li>
                <li>La fonctionnalité doit permettre de <strong>retirer des articles favoris</strong> si nécessaire.</li>
            </ul>
        
            <p class="subTitle">Différence entre User Stories et PBIs</p>
            <p>Les <span class="emphasis">User Stories</span> sont une sous-catégorie des <span class="emphasis">PBIs</span> (<strong>Product Backlog Items</strong>). Leur distinction principale réside dans leur **orientation utilisateur** :</p>
        
            <ul>
                <li><span class="emphasis">User Stories</span> : Elles se concentrent exclusivement sur les <strong>besoins des utilisateurs</strong> et la valeur qu’une fonctionnalité peut apporter.</li>
                <li><span class="emphasis">PBIs</span> : Ils englobent aussi des <strong>tâches techniques, corrections de bugs ou études exploratoires</strong>, qui ne sont pas nécessairement liés à l’expérience utilisateur.</li>
            </ul>
        
            <p class="subTitle">Les User Stories dans Scrum</p>
            <p>Les <span class="emphasis">User Stories</span> favorisent une <strong>collaboration active</strong> et créent une <strong>compréhension commune</strong> entre le <span class="emphasis">Product Owner</span>, l’équipe de développement et les parties prenantes.</p>
        
            <p>En se concentrant sur les <strong>besoins réels des utilisateurs</strong>, elles permettent à l’équipe <span class="emphasis">Scrum</span> de livrer des fonctionnalités qui apportent une <strong>valeur tangible</strong>. Pour garantir leur qualité et leur mise en œuvre efficace, elles doivent respecter le cadre <span class="emphasis">INVEST</span> :</p>
        
            <ul>
                <li><strong>Indépendante</strong> : Chaque Story peut être développée séparément.</li>
                <li><strong>Négociable</strong> : Elle est un point de départ, pas un cahier des charges figé.</li>
                <li><strong>Valorisable</strong> : Elle apporte un bénéfice clair à l’utilisateur ou à l’entreprise.</li>
                <li><strong>Estimable</strong> : L’équipe peut évaluer sa complexité.</li>
                <li><strong>Suffisamment petite</strong> : Elle peut être réalisée dans un Sprint.</li>
                <li><strong>Testable</strong> : Il est possible de vérifier si elle est bien implémentée.</li>
            </ul>
        
            <p class="subTitle">Résumé</p>
            <p>Les <span class="emphasis">User Stories</span> ne sont pas qu’un simple outil pratique : elles incarnent une <strong>philosophie de développement centrée sur l’utilisateur</strong>. Elles <strong>clarifient les attentes</strong>, guident les discussions et permettent à l’équipe de construire des solutions qui <strong>répondent aux véritables besoins des utilisateurs</strong>.</p>
        
            <p>En ce sens, elles jouent un rôle clé dans l’<span class="emphasis">alignement de l’équipe</span> autour d’un objectif commun, tout en restant focalisées sur la <strong>création de valeur</strong>.</p>
        `,
        },
        {
          title: "Incrément",
          content:
            "Un incrément est la somme des PBIs complétés durant un sprint.",
          detailedContent: `
            <p>L’<span class="emphasis">incrément</span> est la manifestation concrète et fonctionnelle du travail accompli par l’équipe <span class="emphasis">Scrum</span> à la fin de chaque Sprint. C'est un <strong>résultat tangible</strong> qui combine les éléments complétés pendant le Sprint avec ceux des incréments précédents, tout en respectant la <span class="emphasis">Definition of Done (DoD)</span>.</p>
        
            <p>Ce qui est essentiel avec l’<span class="emphasis">incrément</span>, c’est qu’il reflète l’essence même de l’<strong>approche empirique</strong> de <span class="emphasis">Scrum</span>. À chaque itération, il permet d’<span class="emphasis">inspecter</span> les progrès du produit et d’<span class="emphasis">ajuster</span> les priorités en fonction des retours des parties prenantes. Ainsi, Sprint après Sprint, l’équipe se rapproche un peu plus de la <strong>vision globale du produit</strong>, tout en restant alignée avec les attentes des utilisateurs.</p>
        
            <p class="subTitle">Un incrément toujours prêt à être livré</p>
            <p>Un <span class="emphasis">incrément</span> est <strong>potentiellement livrable</strong> à tout moment, selon la décision du <span class="emphasis">Product Owner</span>. Il peut être mis à disposition immédiatement ou conservé en attente jusqu’à ce qu’un ensemble suffisant d’incréments soit disponible pour une livraison groupée.</p>
        
            <p>Dans tous les cas, chaque incrément représente une avancée <strong>cohérente</strong> et <strong>solide</strong> vers un produit fonctionnel.</p>
        
            <p class="subTitle">Une garantie de valeur et de qualité</p>
            <p>Un <span class="emphasis">incrément</span> n’est jamais un simple <strong>prototype</strong> ou un travail partiellement terminé. Par définition, il est :</p>
        
            <ul>
                <li><strong>Utilisable</strong>, garantissant qu’il apporte une valeur directe aux utilisateurs.</li>
                <li><strong>Potentiellement livrable</strong>, c’est-à-dire conforme aux standards définis dans la <span class="emphasis">DoD</span>.</li>
                <li><strong>De haute qualité</strong>, intégrant les meilleures pratiques de développement, de tests et de documentation.</li>
            </ul>
        
            <p>Cette rigueur garantit un produit cohérent et évolutif, prêt à être ajusté en fonction des retours, et minimisant ainsi les risques liés aux incertitudes du marché.</p>
        
            <p class="subTitle">Résumé</p>
            <p>L’<span class="emphasis">incrément</span> est la somme des résultats produit par l’équipe. Il incarne la <strong>valeur ajoutée</strong> créée à chaque Sprint et illustre la <strong>progression continue</strong> vers la <strong>vision globale du produit</strong>.</p>
        
            <p>Grâce à lui, <span class="emphasis">Scrum</span> garantit une avancée régulière, assurant que chaque étape franchie est <strong>solide, cohérente et prête à l’usage</strong>. C’est ce qui rend cette approche si puissante dans un environnement en perpétuelle évolution.</p>
        `,
        },
        {
          title: "Backlog",
          content: "Le backlog est une liste priorisée des travaux.",
          detailedContent: `
          <p>Le <span class="emphasis">backlog</span> est le véritable <strong>moteur du développement agile</strong>. Il sert de <strong>liste priorisée et évolutive</strong> qui regroupe toutes les tâches, fonctionnalités, corrections et travaux nécessaires pour atteindre les objectifs du produit.</p>
      
          <p>Ce qui me surprend toujours, c’est son <strong>caractère vivant et flexible</strong> : il évolue en fonction des <strong>besoins des utilisateurs</strong>, des <strong>priorités stratégiques</strong> et des <strong>apprentissages</strong> que l’équipe acquiert au fil des <span class="emphasis">Sprints</span>.</p>
      
          <p class="subTitle">Les deux dimensions du backlog</p>
          <p>Dans <span class="emphasis">Scrum</span>, le <span class="emphasis">backlog</span> se divise en deux parties principales :</p>
      
          <ul>
              <li><span class="emphasis">Product Backlog</span> : Maintenu par le <span class="emphasis">Product Owner</span>, il rassemble tous les éléments nécessaires pour le développement à long terme du produit. Chaque élément, ou <strong>Product Backlog Item (PBI)</strong>, est priorisé en fonction de sa valeur et peut inclure des <strong>fonctionnalités, des corrections ou des tâches techniques</strong>.</li>
              <li><span class="emphasis">Sprint Backlog</span> : Ce sous-ensemble du <span class="emphasis">Product Backlog</span> est sélectionné lors du <span class="emphasis">Sprint Planning</span>. Il représente le travail que l’équipe s’engage à réaliser pendant un Sprint spécifique.</li>
          </ul>
      
          <p class="subTitle">Un outil vivant et collaboratif</p>
          <p>Le <span class="emphasis">backlog</span> n’est pas une simple liste de tâches <strong>figée</strong> dans le temps. C’est avant tout un <strong>plan de développement évolutif</strong> qui demande une <strong>gestion active et collaborative</strong>.</p>
      
          <p>Des activités comme le <span class="emphasis">Product Backlog Refinement</span> permettent de <strong>clarifier, détailler et prioriser</strong> les éléments afin qu’ils soient prêts à être intégrés dans les prochains <span class="emphasis">Sprints</span>.</p>
      
          <p class="subTitle">Les caractéristiques essentielles du backlog</p>
          <p>Voici ce qui rend le <span class="emphasis">backlog</span> indispensable en contexte <span class="emphasis">Scrum</span> :</p>
      
          <ul>
              <li><span class="emphasis">Priorisation</span> : Les éléments les plus <strong>importants ou urgents</strong> sont toujours en haut du <span class="emphasis">backlog</span>, garantissant que l’équipe se concentre sur la <strong>livraison de valeur maximale</strong>.</li>
              <li><span class="emphasis">Évolution continue</span> : Le <span class="emphasis">backlog</span> s’ajuste en permanence en fonction des <strong>retours des parties prenantes</strong>, des <strong>changements stratégiques</strong>, et des <strong>apprentissages</strong> obtenus pendant les <span class="emphasis">Sprints</span>.</li>
              <li><span class="emphasis">Transparence</span> : Il offre une <strong>vision claire</strong> de ce qui a été accompli, de ce qui est en cours, et de ce qui reste à faire. Cette clarté bénéficie autant à l’équipe qu’aux <strong>parties prenantes</strong>.</li>
          </ul>
      
          <p class="subTitle">Résumé</p>
          <p>En résumé, le <span class="emphasis">backlog</span> est un très bon outil de planification. C’est l’une des <strong>boussoles agiles</strong> qui oriente l’équipe <span class="emphasis">Scrum</span> dans sa mission de <strong>livrer régulièrement de la valeur</strong>.</p>
      
          <p>En organisant et en adaptant en permanence les <strong>priorités</strong>, il permet à l’équipe de <strong>répondre aux besoins évolutifs</strong> des utilisateurs et du marché tout en restant concentrée sur ses <strong>objectifs</strong>.</p>
      `,
        },
      ],
    },
    {
      title: "Mesures et Techniques",
      top: "65vh",
      left: "75vw",
      definitions: [
        {
          title: "Vélocité",
          content:
            "La vélocité mesure la quantité de travail complété dans un sprint.",
          detailedContent: `
            <p>La <span class="emphasis">vélocité</span> est  un <strong>outil précieux</strong> de mesure dans Scrum qui aide l’équipe à comprendre son <strong>rythme de travail</strong> et à mieux planifier ses futurs <span class="emphasis">Sprints</span>. Elle permet d’analyser et de quantifier le volume moyen de <span class="emphasis">Product Backlog Items (PBIs)</span> transformés en incrément "<span class="emphasis">Done</span>" au cours d’un Sprint.</p>
        
            <p>Ce qui est essentiel, c’est que la vélocité est une <strong>référence empirique</strong> qui guide l’équipe dans ses engagements, tout en maintenant un <strong>équilibre réaliste</strong> entre ambition et faisabilité.</p>
        
            <p class="subTitle">Comment fonctionne la vélocité ?</p>
            <p>La <span class="emphasis">vélocité</span> repose sur une <strong>observation continue</strong> des résultats. En mesurant le nombre de <span class="emphasis">PBIs</span> complétés à chaque Sprint, l’équipe obtient une <strong>estimation claire</strong> de ce qu’elle peut accomplir.</p>
        
            <p>Cette donnée est particulièrement utile lors du <span class="emphasis">Sprint Planning</span>, où l’équipe sélectionne les éléments à inclure dans le <span class="emphasis">Sprint Backlog</span>. Elle permet d’éviter la surcharge et garantit que l’équipe s’engage sur un volume de travail réaliste.</p>
        
            <p class="subTitle">Un outil d’aide à la planification</p>
            <p>La vélocité aide l’équipe à <strong>mieux gérer ses engagements</strong>. Par exemple :</p>
        
            <ul>
                <li> Si une équipe termine en moyenne <strong>25 points de story par Sprint</strong>, elle peut s’appuyer sur cette donnée pour choisir un nombre équivalent de PBIs pour le Sprint suivant.</li>
                <li> Cette prévisibilité permet une <strong>gestion efficace des attentes des parties prenantes</strong> et évite les engagements irréalistes.</li>
            </ul>
        
            <p class="subTitle">Une mesure interne, pas un indicateur de performance</p>
            <p>Il est crucial de ne pas utiliser la <span class="emphasis">vélocité</span> comme une mesure de productivité ou de comparer la vélocité entre différentes équipes.</p>
        
            <p> <strong>Chaque équipe Scrum a sa propre vélocité</strong>, influencée par des facteurs comme sa composition, son expérience ou sa manière de travailler.</p>
        
            <p>La vélocité est <strong>un outil interne</strong>, conçu pour aider l’équipe à s’aligner sur ses propres objectifs, sans pression extérieure. Elle ne doit jamais devenir une contrainte ou un indicateur de performance imposé par la direction.</p>
        
            <p class="subTitle">Résumé</p>
            <p>La <span class="emphasis">vélocité</span> est un <strong>indicateur empirique</strong> qui améliore la <span class="emphasis">transparence</span> et optimise la <span class="emphasis">planification des Sprints</span>. Elle permet à l’équipe :</p>
        
            <ul>
                <li> De comprendre et d’ajuster son <strong>rythme de travail</strong>.</li>
                <li> De <strong>collaborer plus efficacement</strong> sur la gestion des priorités.</li>
                <li> D’éviter les surcharges tout en <strong>respectant ses engagements</strong>.</li>
            </ul>
        
            <p>En restant centrée sur les besoins et les capacités spécifiques de l’équipe, la vélocité contribue à créer un <strong>environnement de travail équilibré et productif</strong>.</p>
        `,
        },
        {
          title: "Burndown Chart",
          content: "Le Burndown Chart montre la progression pendant le sprint.",
          detailedContent: `
          <p>J’ai toujours vu le <span class="emphasis">Burndown Chart</span> comme l’un des outils visuels les plus <strong>simples</strong> et <strong>efficaces</strong> dans Scrum pour suivre la progression d’un <span class="emphasis">Sprint</span>. Il est malheureusement souvent mal utilisé, alors qu’il offre une <strong>vue d’ensemble claire</strong> du travail restant et aide l’équipe à s’assurer que son rythme de travail est <strong>aligné avec l’objectif du Sprint</strong>.</p>
      
          <p class="subTitle">Structure du Burndown Chart</p>
          <p>Le graphique est composé de deux axes :</p>
          <ul>
              <li><strong>Axe vertical :</strong> représente la quantité de travail restante, généralement exprimée en <span class="emphasis">points de story</span> ou en heures estimées.</li>
              <li><strong>Axe horizontal :</strong> indique le temps, souvent en <strong>jours</strong> ou en étapes du Sprint.</li>
          </ul>
          <p>Au début du Sprint, le <span class="emphasis">Burndown Chart</span> commence avec la totalité du travail prévu. À mesure que le travail est complété, la courbe descend progressivement, <strong>idéalement pour atteindre zéro</strong> à la fin du Sprint.</p>
      
          <p class="subTitle">Pourquoi utiliser un Burndown Chart ?</p>
          <p>Le <span class="emphasis">Burndown Chart</span> remplit plusieurs fonctions essentielles :</p>
          <ul>
              <li><strong>Transparence :</strong> Il donne à l’équipe une <strong>vision claire</strong> de l’avancement du Sprint.</li>
              <li><strong>Détection des problèmes :</strong> Une courbe qui stagne ou ne descend pas assez vite peut indiquer des <span class="emphasis">blocages</span>, des <strong>retards</strong> ou des problèmes de priorisation.</li>
              <li><strong>Amélioration continue :</strong> En analysant les Burndown Charts passés, l’équipe peut <strong>identifier des tendances</strong> et <strong>ajuster ses pratiques</strong> pour gagner en efficacité.</li>
          </ul>
      
          <p class="subTitle">Exemple concret</p>
          <p>Prenons un exemple simple :</p>
          <ul>
              <li>Une équipe commence un Sprint avec <strong>50 points</strong> de travail estimés.</li>
              <li>Elle termine en moyenne <strong>10 points par jour</strong>.</li>
              <li>La courbe descendante devrait donc atteindre zéro à la fin du Sprint.</li>
              <li>Si, au <strong>jour 3</strong>, la courbe stagne, cela peut signaler un obstacle comme un <span class="emphasis">problème technique</span> ou une <span class="emphasis">dépendance non anticipée</span>.</li>
          </ul>
      
          <p class="subTitle">Les limites du Burndown Chart</p>
          <p>Cependant, il est important de reconnaître que le <span class="emphasis">Burndown Chart</span> a ses limites :</p>
          <ul>
              <li>Il ne capture pas les <strong>complexités du travail</strong>, comme les <span class="emphasis">dépendances</span> ou les imprévus.</li>
              <li>Il ne reflète pas toujours l’effort réel si certaines tâches demandent plus de temps que prévu.</li>
              <li>Il ne remplace pas les discussions du <span class="emphasis">Daily Scrum</span>, qui permettent une meilleure compréhension des défis rencontrés.</li>
          </ul>
      
          <p class="subTitle">Résumé</p>
          <p>Le <span class="emphasis">Burndown Chart</span> est un outil <strong>simple mais puissant</strong> pour suivre l’avancement du Sprint. </p>
          <ul>
              <li><strong>Favorise la transparence</strong> sur la progression du travail.</li>
              <li><strong>Soutient l’inspection quotidienne</strong> et l’adaptation.</li>
              <li><strong>Aide l’équipe Scrum</strong> à ajuster son effort pour atteindre ses objectifs.</li>
          </ul>
          <p>Il est un moyen de <strong>créer une culture de clarté et de collaboration</strong>, essentielle dans un cadre agile.</p>
      `,
        },
        {
          title: "Estimation",
          content:
            "Estimer la taille des PBIs est un outil de planification clé.",
          detailedContent: `
            <p><span class="emphasis">L’estimation</span> est un élément clé qui permet à l’équipe de comprendre <strong>l’effort nécessaire</strong> pour accomplir chaque <span class="emphasis">Product Backlog Item (PBI)</span> et de planifier efficacement ses <span class="emphasis">Sprints</span>. Elle ne vise pas à prédire un chiffre exact, mais à fournir une <strong>évaluation relative</strong> de la taille ou de la complexité d’un PBI, tout en tenant compte des risques associés.</p>
        
            <p class="subTitle">Comment fonctionne l’estimation ?</p>
            <p>Les estimations sont souvent exprimées en <span class="emphasis">points de story</span> ou dans une autre unité relative définie par l’équipe. Cela permet de comparer les PBIs entre eux pour en déterminer la taille respective. La pratique la plus courante en agilité est de noter en suivant la <strong>suite de Fibonacci</strong> (1-2-3-5-8-13-21...), mais l’équipe est libre de choisir sa propre échelle (bananes, tonnes, unités astronomiques...), tant qu’elle partage un référentiel commun.</p>
        
            <p>Par exemple :</p>
            <ul>
                <li>Une fonctionnalité complexe pourrait être estimée à <strong>8 points</strong>.</li>
                <li>Un correctif mineur pourrait être évalué à <strong>1 point</strong>.</li>
            </ul>
        
            <p>Ce qui est recommandé pour bien maîtriser cette approche est de <strong>comparer les PBIs entre eux</strong>, afin de les classer en groupes de complexité. Cette technique comparative permet à l’équipe de mieux visualiser la charge de travail et d’organiser ses efforts en conséquence.</p>
        
            <p class="subTitle">L’impact de l’estimation dans Scrum</p>
            <p>L’<span class="emphasis">estimation</span> joue plusieurs rôles essentiels :</p>
            <ul>
                <li><strong>Aide à la priorisation</strong> : Elle permet au <span class="emphasis">Product Owner</span> de classer les PBIs en fonction de leur valeur et de l’effort requis.</li>
                <li><strong>Soutient la planification</strong> : Pendant le <span class="emphasis">Sprint Planning</span>, elle aide l’équipe à choisir un volume de travail réaliste.</li>
                <li><strong>Facilite la prévisibilité</strong> : Elle contribue à prévoir les délais et les ressources nécessaires pour atteindre les objectifs à long terme.</li>
            </ul>
                
            <p class="subTitle">Collaboration et outils</p>
            <p>L’<span class="emphasis">estimation</span> repose sur une <strong>collaboration étroite</strong> entre le <span class="emphasis">Product Owner</span> et l’équipe de développement. Des techniques comme le <span class="emphasis">Planning Poker</span> permettent d’arriver à un consensus tout en favorisant une compréhension commune des PBIs.</p>
        
            <p>Ces discussions ne se limitent pas à l’effort nécessaire : elles renforcent également <strong>l’alignement</strong> et la <strong>communication</strong> au sein de l’équipe.</p>
        
            <p class="subTitle">Résumé</p>
            <p>La <span class="emphasis">estimation</span> est une pratique essentielle qui soutient :</p>
            <ul>
                <li><strong>La priorisation</strong> des PBIs en fonction de leur valeur et de leur complexité.</li>
                <li><strong>La planification des Sprints</strong>, permettant d’adapter la charge de travail.</li>
                <li><strong>La prévisibilité</strong>, en aidant l’équipe à mieux anticiper les efforts nécessaires.</li>
            </ul>
        
            <p><span class="emphasis">L'estimation</span> est un véritable <strong>levier stratégique</strong> qui guide l’équipe dans la livraison continue de valeur et l’amélioration de son efficacité.</p>
        `,
        },
      ],
    },
    {
      title: "Principes et Piliers de Scrum",
      top: "30vh",
      left: "60vw",
      definitions: [
        {
          title: "Transparence",
          content: "La transparence est un pilier essentiel de Scrum.",
          detailedContent: `
          <p>La <span class="emphasis">transparence</span> est ma <strong>valeur agile favorite</strong> (on préfère toujours le petit oublié de la famille). Elle est une condition essentielle pour permettre aux équipes de naviguer dans la <strong>complexité, l’ambiguïté et l’incertitude</strong>.</p>
      
          <p>Il ne s’agit pas seulement de rendre visibles les artefacts et les processus, mais aussi de <strong>mettre en lumière les faits</strong> – même ceux qui peuvent être inconfortables ou remettre en question des croyances bien établies.</p>
      
          <p class="subTitle">La transparence, clé de l’agilité</p>
          <p>La <span class="emphasis">transparence</span> peut parfois être <strong>déstabilisante</strong>, notamment pour les organisations. Par exemple, un retour client peut révéler qu’un élément de <span class="emphasis">Product Backlog</span>, jugé "essentiel" par un décideur, n’apporte en réalité <strong>aucune valeur</strong>.</p>
      
          <p>Ou encore, elle peut exposer des <strong>lacunes</strong> dans la qualité du travail, même chez des membres seniors de l’équipe. Ces vérités, bien que nécessaires, demandent du <strong>courage</strong> pour être acceptées et une véritable <span class="emphasis">sécurité psychologique</span> pour être partagées sans crainte.</p>
      
          <p class="subTitle">La sécurité psychologique, un levier indispensable</p>
          <p>Pour que la <span class="emphasis">transparence</span> puisse vraiment fonctionner, les équipes doivent évoluer dans un environnement où elles se sentent en <strong>sécurité</strong>. Cela repose sur trois éléments clés :</p>
      
          <ul>
              <li>Des <strong>discussions honnêtes et ouvertes</strong>, même sur des sujets difficiles.</li>
              <li>L’exploration des <strong>opinions divergentes</strong>, sans crainte de répercussions négatives.</li>
              <li>Une conviction partagée : <strong>"Everyone is doing their best with what they know at the time"</strong> (Ockerman, S., & Reindl, S., <i>Mastering Professional Scrum</i>, 2019).</li>
          </ul>
      
          <p>Ce que je trouve particulièrement important, c’est que les <strong>leaders</strong> jouent un rôle central dans cette démarche. En montrant l’exemple et en protégeant les équipes des <strong>conséquences négatives</strong> liées à la <span class="emphasis">transparence</span>, ils créent une culture où "<strong>les faits sont nos amis</strong>".</p>
      
          <p>Avec des <strong>informations claires et fiables</strong>, les équipes peuvent <span class="emphasis">inspecter</span> et <span class="emphasis">s’adapter</span> efficacement, ce qui améliore non seulement leur travail, mais aussi leur collaboration.</p>
      
          <p class="subTitle">Optimisme et réalisme : un équilibre nécessaire</p>
          <p>La <span class="emphasis">transparence</span> permet aussi de garder un <strong>équilibre sain</strong> entre optimisme et réalisme. Être positif est important, mais cet optimisme doit s’appuyer sur des <strong>faits</strong>, pas sur des illusions.</p>
      
          <p>C’est pourquoi j’aime cette idée que la <span class="emphasis">transparence</span> est un <strong>antidote aux attentes irréalistes</strong> : elle permet de construire un optimisme <strong>ancré dans la réalité</strong>, ce qui renforce la <strong>confiance</strong> et la cohérence au sein de l’équipe.</p>
      
          <p class="subTitle">Résumé</p>
          <p>En réalité, la <span class="emphasis">transparence</span> repose sur la <strong>confiance, le courage et une intelligence émotionnelle authentique</strong>.</p>
      
          <p>C’est une discipline qui <strong>aligne les équipes</strong> sur les réalités du travail, favorise l’<span class="emphasis">amélioration continue</span> et, surtout, rend <span class="emphasis">Scrum</span> vraiment efficace.</p>
      
          <p>À mes yeux, elle est l’un des <strong>piliers les plus puissants</strong> pour transformer non seulement le produit, mais aussi les dynamiques d’équipe et la culture organisationnelle.</p>
      `,
        },
        {
          title: "Inspection",
          content: "L'inspection aide à détecter les écarts indésirables.",
          detailedContent: `
          <p>Bon, nous rentrons ici dans le <strong>cœur même de l’agilité</strong>. Pour moi, l’<span class="emphasis">inspection</span> dans <span class="emphasis">Scrum</span> est un <strong>véritable acte d’apprentissage collectif</strong>.</p>
      
          <p>Elle repose sur la capacité de l’équipe <span class="emphasis">Scrum</span> à <strong>examiner régulièrement ses résultats</strong>, analyser les écarts entre les objectifs fixés et ce qui a été réalisé, et <strong>tirer des leçons</strong> aussi bien des succès que des insuffisances.</p>
      
          <p class="subTitle">L’inspection, un apprentissage collectif</p>
          <p>L’<span class="emphasis">inspection</span> pousse chaque membre de l’équipe à <strong>réfléchir activement</strong> sur ce qui a bien fonctionné et ce qui aurait pu être meilleur. Mais attention, on ne parle pas de <strong>critique stérile</strong> : tout est basé sur des faits et des <strong>retours concrets</strong>.</p>
      
          <p>En observant objectivement les résultats et en acceptant la réalité telle qu’elle est, l’équipe peut <strong>construire des bases solides</strong> pour progresser.</p>
      
          <p class="subTitle">Une dimension humaine et empirique</p>
          <p>Ce qui m’a toujours marqué, c’est la <strong>dimension humaine</strong> de l’<span class="emphasis">inspection</span>. Ce n’est pas toujours facile : cela demande du <strong>courage</strong> et une dose d’<strong>intelligence émotionnelle</strong> pour regarder les choses en face, même quand elles sont inconfortables.</p>
      
          <p>Mais c’est aussi une <strong>chance unique</strong> d’identifier des lacunes, de mettre en lumière des <span class="emphasis">dépendances non anticipées</span> ou de trouver des pistes d’amélioration.</p>
      
          <p>Et surtout, c’est une opportunité de <strong>célébrer les progrès</strong> réalisés et de renforcer la <strong>confiance collective</strong> au sein de l’équipe.</p>
      
          <p class="subTitle">De l’analyse à l’adaptation</p>
          <p>Prenons un exemple : lors d’une <span class="emphasis">Sprint Review</span>, l’équipe remarque que certaines fonctionnalités livrées ne sont pas utilisées comme prévu par les utilisateurs.</p>
      
          <p>Plutôt que de considérer cela comme un échec, l’équipe collecte ces retours pour ajuster le <span class="emphasis">Product Backlog</span>, s’assurant ainsi que les prochains <span class="emphasis">incréments</span> apporteront une réelle valeur.</p>
      
          <p>En gros, l’<span class="emphasis">inspection</span>, c’est <strong>transformer les imprévus en opportunités d’amélioration</strong>.</p>
      
          <p class="subTitle">L’inspection comme catalyseur</p>
          <p>Pour être efficace, l’<span class="emphasis">inspection</span> doit se dérouler dans un climat de <span class="emphasis">transparence</span> et de <strong>confiance</strong>. Chaque membre doit se sentir libre de partager ses observations, <strong>sans crainte de jugement</strong>.</p>
      
          <p>Cet état d’esprit, ancré dans la <strong>transparence</strong>, est indispensable pour favoriser l’<span class="emphasis">adaptation</span> et l’<span class="emphasis">amélioration continue</span>.</p>
      
          <p class="subTitle">Résumé</p>
          <p>En résumé, l’<span class="emphasis">inspection</span> n’est pas un simple contrôle, c’est un moment de <strong>réflexion collective, de remise en question</strong> et d’<strong>apprentissage actif</strong>. Elle permet à l’équipe de <strong>s’adapter, de progresser</strong> et de rester fidèle à la <strong>vision du produit</strong>. C’est l’un des moteurs les plus puissants de l’<span class="emphasis">agilité</span> dans <span class="emphasis">Scrum</span>, et l’un de mes <strong>aspects préférés</strong> du cadre.</p>
      `,
        },
        {
          title: "Adaptation",
          content: "L'adaptation permet aux équipes de s'ajuster au besoin.",
          detailedContent: `
          <p>L’<span class="emphasis">adaptation</span>, c’est l’illustration même de l’<span class="emphasis">agilité</span> dans <span class="emphasis">Scrum</span>. C’est cette capacité qu’ont les équipes à <strong>ajuster leur travail, leurs priorités et leurs processus</strong> en fonction des nouvelles informations, des imprévus, ou des besoins changeants.</p>
      
          <p><span class="emphasis">L'adaptation</span> reflète une <strong>mentalité agile et proactive</strong>, où chaque apprentissage se transforme en <strong>action concrète</strong>. Selon moi, elle est fortement liée à la <strong>résilience</strong>, notre capacité à <strong>résister avant de s’adapter</strong> pour mieux réagir aux problématiques qui nous entourent. C’est notre faculté à <strong>apprendre de nos erreurs</strong>.</p>
      
          <p class="subTitle">L’adaptation, moteur de l’agilité</p>
          <p>Dans <span class="emphasis">Scrum</span>, l’<span class="emphasis">adaptation</span> repose sur des cycles réguliers d’<span class="emphasis">inspection</span>. Après avoir observé les écarts ou identifié des opportunités, l’équipe analyse ces informations pour prendre des <strong>décisions éclairées</strong>.</p>
      
          <p>Que ce soit en ajustant le <span class="emphasis">Product Backlog</span>, en révisant une méthode de travail, ou en changeant d’approche, l’objectif est toujours le même : <strong>maintenir un alignement constant</strong> entre le produit, les attentes des utilisateurs, et les besoins du marché.</p>
      
          <p class="subTitle">Une dynamique collective</p>
          <p>L’<span class="emphasis">adaptation</span>, il faut bien s’en rendre compte, est aussi le résultat d’un <span class="emphasis">effort collectif</span>. Toute l’équipe participe, partageant ses idées, ses observations et ses suggestions d’<span class="emphasis">amélioration continue</span>.</p>
      
          <p>Mais cela demande aussi du <strong>courage</strong>. S’adapter peut signifier <strong>revoir des choix passés</strong>, abandonner une méthode qui semblait prometteuse, ou faire face à des vérités parfois inconfortables.</p>
      
          <p>C’est un <strong>exercice de confiance mutuelle</strong>, où chaque membre de l’équipe joue un rôle actif.</p>
      
          <p class="subTitle">Le lien entre adaptation et inspection</p>
          <p>Pour moi, l’<span class="emphasis">adaptation</span> ne peut pas exister sans <span class="emphasis">inspection</span> ni <span class="emphasis">transparence</span>. Ces piliers fournissent les <strong>données nécessaires</strong> pour évaluer la situation actuelle et identifier les <strong>ajustements à apporter</strong>.</p>
      
          <p>Ce cycle continu, ancré dans l’<span class="emphasis">empirisme</span>, garantit que l’équipe <span class="emphasis">Scrum</span> avance de manière <strong>alignée</strong>, tout en restant <strong>flexible et efficace</strong>.</p>
      
          <p class="subTitle">Résumé</p>
          <p>En résumé, l’<span class="emphasis">adaptation</span>, c’est la capacité d’une équipe à <strong>transformer ses apprentissages en actions concrètes</strong>, à <strong>réorienter sa trajectoire</strong> face aux nouvelles réalités, et à <strong>continuer à livrer de la valeur</strong>, même en situation d’incertitude.</p>
      
          <p>À mes yeux, c’est un <strong>pilier fondamental</strong> qui rend <span class="emphasis">Scrum</span> <strong>résilient, efficace et incroyablement pertinent</strong> dans un monde en perpétuel changement.</p>
      `,
        },
      ],
    },
    {
      title: "Valeurs Scrum et Principes Agiles",
      top: "30vh",
      left: "34vw",
      definitions: [
        {
          title: "Empirisme",
          content:
            "Scrum repose sur l'empirisme, où la prise de décision s'appuient sur l'expérience.",
          detailedContent: `
            <p><span class="emphasis">L’empirisme</span> est un des piliers fondamentaux de la méthodologie Agile, et plus particulièrement de <span class="emphasis">Scrum</span>. C’est avant tout <strong>une manière de penser et d’agir</strong> (le fameux mindset agile) : avancer en s'appuyant sur ce qu'on observe et apprend directement sur le terrain, plutôt que de s'accrocher à des prévisions figées. Dans un monde où tout évolue rapidement – besoins des clients, marchés, contraintes techniques – <span class="emphasis">l'empirisme</span> nous aide à <strong>rester connectés à la réalité</strong> et à prendre des décisions qui ont du sens.</p>
        
            <p class="subTitle">Scrum, un cadre empirique</p>
            <p><span class="emphasis">Scrum</span> a justement été conçu pour encourager cette approche empirique, en posant une question simple : Et si nous arrêtions de tout planifier pour plutôt laisser nos résultats parler d’eux-mêmes ? Chaque sprint, l’équipe livre un petit morceau de produit utilisable, un <span class="emphasis">incrément</span>. Cela permet de tester des idées, de valider ce qui marche, ou au contraire d’ajuster ce qui ne fonctionne pas. Contrairement aux méthodes traditionnelles qui s’accrochent à des plans figées, <span class="emphasis">Scrum</span> requiert que l’on écoute la réalité des faits et en tire un apprentissage. C’est un processus où l’on <strong>apprend en faisant</strong>, où chaque <span class="emphasis">itération</span> est une opportunité de <strong>s’améliorer à chaque étape</strong>, de mieux comprendre les besoins des utilisateurs et d’affiner le fonctionnement de l’équipe.</p>
        
            <p class="subTitle">L’empirisme, une philosophie d’apprentissage</p>
            <p><span class="emphasis">L’empirisme</span> incarne pour moi une certaine philosophie “d’humilité”. C’est accepter qu’on ne sait pas tout dès le départ, et qu’il est normal de se tromper. Ce qui compte, ce n’est pas d’être parfait dès le premier coup, mais d’<strong>apprendre et de s’améliorer à chaque étape</strong>. Par exemple, livrer un produit <span class="emphasis">"Done"</span> à la fin d’un sprint, ce n’est pas juste cocher une case. C’est surtout une occasion de regarder <strong>ce qu’on a bien fait, ce qu’on pourrait améliorer, et comment faire mieux au sprint suivant</strong>. C’est un <strong>état d’esprit</strong> qui valorise <span class="emphasis">l’amélioration continue</span> et <span class="emphasis">l’apprentissage</span>, plutôt que la poursuite d’une perfection hypothétique (et souvent idéalisée d’ailleurs).</p>
        
            <p class="subTitle">L’empirisme, un levier clé en Agilité</p>
            <p>En fait, je vois <span class="emphasis">l’empirisme</span> comme <strong>LE levier essentiel</strong>. Il permet de <strong>réduire l’incertitude</strong> en s’appuyant sur des faits concrets, sur ce qu’on a vécu et appris. Et grâce à cela, on devient non seulement <strong>réactifs face aux changements</strong>, mais aussi capables d’anticiper et de s’adapter avec confiance. C’est ce qui nous aide à <strong>rester en phase avec les attentes</strong> des parties prenantes, tout en minimisant les risques et en maximisant la valeur créée.</p>
        
            <p class="subTitle">Résumé</p>
            <p>En résumé, <span class="emphasis">l’empirisme</span> est <strong>une manière de travailler, d’apprendre, et d’évoluer ensemble</strong> dans un monde en perpétuel mouvement. C’est ce qui nous permet de <strong>rester agiles, de progresser constamment</strong>, et surtout, de <strong>créer de la valeur utile</strong> pour les utilisateurs.</p>
        `,
        },

        {
          title: "Collaboration et travail d'équipe",
          content:
            "Scrum repose sur les collaborations, favorisant réactivité, innovation et valeur continue.",
          detailedContent: `
            <p>La <span class="emphasis">collaboration</span> et le <span class="emphasis">travail d’équipe</span> sont au cœur de la méthodologie <span class="emphasis">Scrum</span>. C’est ce qui permet aux équipes de relever des défis complexes tout en assurant une <span class="emphasis">amélioration continue</span>. Travailler ensemble de manière fluide et adaptable permet à chaque membre de contribuer pleinement à la réussite globale du projet.</p>
        
            <p class="subTitle">Équipe pluridisciplinaire</p>
            <p>Les équipes <span class="emphasis">Scrum</span> sont composées de membres aux compétences complémentaires, ce qui leur permet d’accomplir leurs tâches de manière autonome sans dépendre de ressources externes. Cette diversité est une vraie force : elle permet de <strong>réagir rapidement aux changements</strong> et de livrer des produits de haute qualité. Pas besoin que chacun soit un expert dans tous les domaines, l’important est de tirer parti des spécialisations de chacun dans le cadre d’un effort collectif.</p>
        
            <p class="subTitle">Auto-organisation</p>
            <p>Dans <span class="emphasis">Scrum</span>, l’équipe est maîtresse de son organisation. Elle décide elle-même de la manière la plus efficace pour atteindre ses objectifs. Cette <strong>autonomie</strong> donne à chaque membre un vrai sentiment de responsabilité et d’appropriation du travail. Cela motive l’équipe à trouver des solutions créatives et à s’adapter rapidement aux nouveaux défis.</p>
        
            <p class="subTitle">Travail collaboratif</p>
            <p>Le <span class="emphasis">travail collaboratif</span> est littéralement juste travailler "côte à côte" : c’est <strong>partager des idées</strong>, résoudre des problèmes ensemble et <strong>apprendre les uns des autres</strong>. En créant un environnement où la <strong>communication est fluide</strong> et ouverte, <span class="emphasis">Scrum</span> permet à l’équipe de réagir vite et d’innover plus facilement. Les réunions quotidiennes, par exemple, permettent à chacun de comprendre où en est le projet, ce qui renforce l’<strong>efficacité collective</strong>.</p>
        
            <p class="subTitle">Cohésion d’équipe</p>
            <p>Une équipe qui travaille ensemble sur la durée développe naturellement une forte <span class="emphasis">cohésion</span> et une dynamique fluide. Cette stabilité renforce la <strong>confiance entre les membres</strong> et améliore la communication. Mais attention : lorsque l’équipe change, il faut un effort conscient pour intégrer les nouveaux venus et maintenir cette cohésion.</p>
        
                        <p class="subTitle">Résumé</p>
            <p>En conclusion, la <span class="emphasis">collaboration</span> dans <span class="emphasis">Scrum</span> repose sur une approche <span class="emphasis">pluridisciplinaire</span> et <span class="emphasis">auto-organisée</span>. Elle favorise l’<strong>innovation</strong>, renforce la <strong>communication</strong> et permet à l’équipe de s’adapter rapidement aux changements. Ce mode de fonctionnement assure la <strong>livraison régulière de valeur</strong> tout en créant un environnement de travail <strong>fluide, motivant et transparent</strong>.</p>
        `,
        },
        {
          title: "Développement Itératif",
          content:
            "Scrum favorise l'amélioration continue par des livraisons et des ajustements réguliers.",
          detailedContent: `
            <p>Le <span class="emphasis">développement itératif</span> est l’une des pratiques les plus puissantes de <span class="emphasis">Scrum</span>. C’est une façon de travailler qui permet à l’équipe d’<strong>évoluer et d’améliorer</strong> son produit pas à pas, en écoutant les retours et en s’adaptant au fur et à mesure. Plutôt que d’essayer de tout prévoir et livrer en une fois, on avance par petites étapes appelées <span class="emphasis">sprints</span>. Chaque <span class="emphasis">sprint</span> produit quelque chose de concret, un <span class="emphasis">incrément fonctionnel</span>, qui peut être utilisé et évalué.</p>
        
            <p class="subTitle">Une boucle de retours continue</p>
            <p>Ce qui rend cette approche si efficace, c’est la <span class="emphasis">boucle de retours</span> qu’elle crée. En livrant régulièrement des <span class="emphasis">incréments</span>, l’équipe obtient rapidement des retours des utilisateurs ou des parties prenantes. Cela lui permet d’<strong>ajuster non seulement le produit, mais aussi ses processus de travail</strong>. Chaque <span class="emphasis">sprint</span> devient alors une <strong>opportunité d’apprendre</strong>, d’améliorer, et de <strong>réduire les risques</strong>. En validant ou en corrigeant des hypothèses au fil du temps, on évite que de gros problèmes s’accumulent.</p>
        
            <p class="subTitle">Un moteur d’innovation</p>
            <p>Mais le <span class="emphasis">développement itératif</span> ne se limite pas à résoudre des problèmes. C’est aussi un formidable moteur d’<span class="emphasis">innovation</span>. Chaque <span class="emphasis">sprint</span> donne l’occasion d’<strong>expérimenter de nouvelles idées</strong>, d’explorer des pistes différentes, et d’optimiser non seulement le produit, mais aussi la manière dont on travaille ensemble. Cette approche garantit que l’équipe reste <span class="emphasis">flexible</span> et <strong>réactive</strong> tout en maintenant un cap clair vers ses objectifs à long terme.</p>
        
            <p class="subTitle">Résumé</p>
            <p>En bref, le <span class="emphasis">développement itératif</span>, c’est un peu comme gravir une montagne : on progresse à petits pas, mais chaque pas nous rapproche du sommet, avec la liberté de <strong>changer d’itinéraire si nécessaire</strong>.</p>
        `,
        },
        {
          title: "Valeurs Scrum",
          content:
            "Scrum repose sur cinq valeurs : Courage, Focus, Engagement, Respect, et Ouverture.",
          detailedContent: `
            <p><span class="emphasis">Les valeurs Scrum</span> sont un peu comme les fondations d’une maison : elles maintiennent tout en place et permettent à l’équipe de se développer dans un environnement sain et productif. Ces valeurs favorisent <strong>la collaboration</strong> et <strong>la transparence</strong>, deux ingrédients indispensables pour relever les défis des environnements complexes.</p>
        
            <p class="subTitle">Courage</p>
            <p>Le <span class="emphasis">courage</span>, c’est oser affronter les problèmes de face, sans peur de se tromper ou d’échouer. Il permet à l’équipe de prendre des décisions difficiles et d’explorer des solutions innovantes. Mais ce n’est pas qu’une question d’état d’esprit : c’est aussi une culture que le <span class="emphasis">Scrum Master</span> nourrit en protégeant l’équipe des pressions inutiles et en encourageant une prise de risques réfléchie. <strong>Sans courage, pas de progrès !</strong></p>
        
            <p class="subTitle">Focus</p>
            <p>Dans un monde plein de distractions, le <span class="emphasis">focus</span> est <strong>une boussole</strong> qui permet de garder le cap. Il aide l’équipe à rester concentrée sur les objectifs du <span class="emphasis">Sprint</span> et à éviter de se disperser. Chaque <span class="emphasis">Sprint</span> est une chance de <strong>créer de la valeur</strong>, et cette priorité à l’essentiel garantit qu’on avance dans la bonne direction.</p>
        
            <p class="subTitle">Engagement</p>
            <p>L’<span class="emphasis">engagement</span>est une promesse d'<strong>une attitude partagée</strong>. Chaque membre de l’équipe se sent investi dans la réalisation des objectifs et donne le meilleur de lui-même pour y parvenir. Cet engagement crée une vraie cohésion et pousse tout le monde à aller au-delà des attentes.</p>
        
            <p class="subTitle">Respect</p>
            <p>Le <span class="emphasis">respect</span>, c’est <strong>la base de toute relation saine</strong>. Dans une équipe <span class="emphasis">Scrum</span>, cela veut dire <strong>écouter vraiment</strong>, valoriser les idées des autres et reconnaître que chacun apporte une contribution unique. Quand il y a du respect, les discussions deviennent plus productives et les conflits se transforment en opportunités d’amélioration.</p>
        
            <p class="subTitle">Ouverture</p>
            <p>L’<span class="emphasis">ouverture</span>, c’est l’envie de partager, d’être transparent sur les progrès et les problèmes. C’est essentiel pour <strong>bâtir une confiance solide</strong>, que ce soit au sein de l’équipe ou avec les parties prenantes. En étant honnêtes sur les défis, on peut les résoudre plus vite et avancer ensemble.</p>

            <p class="subTitle">Résumé</p>
            <p><strong>Ces valeurs permettent à une équipe <span class="emphasis">Scrum</span> de vraiment s’épanouir. Ensemble, elles créent un environnement où chacun peut <strong>donner le meilleur de lui-même</strong> tout en restant <strong>adaptable, aligné sur les objectifs et porteur de valeur</strong> pour l’ensemble du projet.</p>
        `,
        },
      ],
    },
  ],
};
