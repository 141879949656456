import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import "./DetailedDefinitionModal.css";

const DetailedDefinitionModal = ({ definition, onClose }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const modal = modalRef.current;

    gsap.fromTo(
      modal,
      {
        height: 0,
        opacity: 0,
        transformOrigin: "top",
        scale: 0.8,
      },
      {
        height: "70vh",
        opacity: 1,
        scale: 1,
        duration: 0.8,
        ease: "power1.inOut",
      }
    );

    return () => {};
  }, []);

  const handleClose = () => {
    gsap.to(modalRef.current, {
      height: 0,
      opacity: 0,
      duration: 0.8,
      ease: "power2.inOut",
      onComplete: onClose,
    });
  };

  return (
    <div className="modalOverlay" onClick={handleClose}>
      <div
        className="modalContent"
        ref={modalRef}
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="modalTitle">{definition.title}</h2>
        <div
          className="modalBody"
          dangerouslySetInnerHTML={{ __html: definition.detailedContent }}
        ></div>{" "}
        <button className="closeButton" onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default DetailedDefinitionModal;
