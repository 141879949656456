import React, { useState } from "react";
import "./HamburgerMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { logButtonClick } from "@utils/analytics";
import { useNavigate } from "react-router-dom";

const HamburgerMenu = ({ lang, texts, isBaseCampPage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToPercentage = (percentage) => {
    const scrollHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollToPosition = (percentage / 100) * scrollHeight;
    window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
  };

  return (
    <div className="hamburger-menu">
      <FontAwesomeIcon
        icon={isOpen ? faTimes : faBars}
        className="hamburger-icon"
        onClick={toggleMenu}
      />
      {isOpen && (
        <div className="menu-items">
          {!isBaseCampPage ? (
            <>
              <span
                onClick={() => {
                  logButtonClick("HamburgerMenu", "Click", "Home");
                  scrollToPercentage(8);
                  toggleMenu();
                }}
              >
                {texts[lang].navbar.home}
              </span>
              <span
                onClick={() => {
                  logButtonClick("HamburgerMenu", "Click", "Skills");
                  scrollToPercentage(25);
                  toggleMenu();
                }}
              >
                {texts[lang].navbar.skills.main}
              </span>
              <span
                onClick={() => {
                  logButtonClick("HamburgerMenu", "Click", "Role");
                  scrollToPercentage(61);
                  toggleMenu();
                }}
              >
                {texts[lang].navbar.role}
              </span>
              <span
                onClick={() => {
                  logButtonClick("HamburgerMenu", "Click", "Projects");
                  scrollToPercentage(68);
                  toggleMenu();
                }}
              >
                {texts[lang].navbar.project.main}
              </span>
              <span
                onClick={() => {
                  logButtonClick("HamburgerMenu", "Click", "Journey");
                  scrollToPercentage(86);
                  toggleMenu();
                }}
              >
                {texts[lang].navbar.journey}
              </span>
              <span
                onClick={() => {
                  logButtonClick("HamburgerMenu", "Click", "Contact");
                  scrollToPercentage(99);
                  toggleMenu();
                }}
              >
                {texts[lang].navbar.contact.main}
              </span>
              <span
                onClick={() => {
                  logButtonClick("HamburgerMenu", "Click", "BaseCamp");
                  navigate("/baseCamp");
                  toggleMenu();
                }}
              >
                {texts[lang].navbar.baseCamp.split(" ")[0]}
                <br />
                {texts[lang].navbar.baseCamp.split(" ")[1]}
              </span>
            </>
          ) : (
            <span
              onClick={() => {
                logButtonClick("HamburgerMenu", "Click", "HomePage");
                navigate("/");
                toggleMenu();
              }}
            >
              {texts[lang].navbar.homePage}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
