import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import "./DefinitionPanel.css";
import DetailedDefinitionModal from "./DetailedDefinitionModal";
import { gsap } from "gsap";

const DefinitionPanel = ({
  title,
  definitions,
  top,
  left,
  isOpen,
  onToggle,
  panelIndex,
}) => {
  const [selectedDefinition, setSelectedDefinition] = useState(null);
  const [secondaryPanelsVisible, setSecondaryPanelsVisible] = useState(false);
  const groupRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setSecondaryPanelsVisible(true);
      }, 100);
    } else {
      setSecondaryPanelsVisible(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (groupRef.current) {
      gsap.to(groupRef.current, {
        y: "+=15",
        duration: 5,
        repeat: -1,
        yoyo: true,
        ease: "sine.inOut",
        delay: Math.random() * 3,
      });
    }
  }, []);

  const openDetailedModal = (definition) => {
    setSelectedDefinition(definition);
  };

  const closeModal = () => {
    setSelectedDefinition(null);
  };

  return (
    <div
      className={`mainPanelContainer panel-group-${panelIndex}`}
      style={{ top, left }}
      ref={groupRef}
    >
      <div className="mainPanel" onClick={onToggle}>
        {title}
      </div>

      <div className="secondaryPanels">
        {definitions.map((definition, index) => (
          <div
            key={index}
            className={`secondaryPanel panel-${index} ${
              secondaryPanelsVisible ? "is-open" : ""
            }`}
            onClick={() => openDetailedModal(definition)}
          >
            <div className="definitionTitle">{definition.title}</div>
            <div className="definitionContent">{definition.content}</div>
          </div>
        ))}
      </div>

      {selectedDefinition &&
        createPortal(
          <DetailedDefinitionModal
            definition={selectedDefinition}
            onClose={closeModal}
          />,
          document.body
        )}
    </div>
  );
};

export default DefinitionPanel;
